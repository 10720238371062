import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import BlogPreview from "./BlogPreview";
import {Container, Row, Col, Form, FormGroup, InputGroup, Input, Label, Button, Alert, Media} from 'reactstrap'
import axios from 'axios'
import {Editor} from "@tinymce/tinymce-react";
import {getBlog} from "../../../actions/general/generalActions";
import {EditorState} from "draft-js";
import {stateFromHTML} from "draft-js-import-html";

const EditBlog = (props) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [blogTitle, setBlogTitle] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [previewText, setPreviewText] = useState('')
    const [author, setAuthor] = useState('')
    const [image, setImage] = useState('')
    const [blogContent, setBlogContent] = useState('')
    const [blogImage, setBlogImage] = useState(general.imageUpload)
    const [imageFile, setImageFile] = useState('')
    const [contentImage, setContentImage] = useState('')
    const [hasReceivedData, setHasReceivedDate] = useState(false)
    const [hasUpdated, setHasUpdated] = useState(false)
    const [date, setDate] = useState('')
    const [image64, setImage64] = useState('')


    useEffect(()=>{
        dispatch(getBlog(props.match.params.id))
        if(general.currentBlog[0] === undefined) {

        }

    }, [])

    useEffect(()=> {
        if(general.currentBlog[0] !== undefined) {
            if(hasReceivedData === false) {
                setData()
            }
        }
    })

    const convertFileToBase64 = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = function(e) {
            setImage64(reader.result)
        }
    }


    const SetBlogContent = () => {
        if(hasReceivedData === true) {
            return  <Editor
                apiKey='ewwrgr426zy6sptaah1255v6yyqp01jgen6yi8umxvqqalkd'
                initialValue={blogContent}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable autoresize',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    image_advtab: true,

                    /* we override default upload handler to simulate successful upload*/
                    images_upload_handler: function (blobInfo, success, failure) {

                    },

                }}
                onEditorChange={handleEditorChange}
            />
        }
    }

    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
        setBlogContent(content)
    }

    const setData = () => {
        setBlogTitle(general.currentBlog[0].blog_title)
        setPreviewTitle(general.currentBlog[0].blog_preview_title)
        setAuthor(general.currentBlog[0].blog_writer)
        setPreviewText(general.currentBlog[0].blog_preview_text)
        setImage(general.currentBlog[0].blog_preview_image)
        setBlogContent(general.currentBlog[0].blog_content)
        setDate(general.currentBlog[0].date)
        setHasReceivedDate(true)
        setImage64(`https://nestidd-assets.s3.us-east-2.amazonaws.com/${general.currentBlog[0].blog_preview_image}`)

    }


    const handleChange = e => {
        if(e.target.name == 'blog_title') {
            setBlogTitle(e.target.value)
        }
        if(e.target.name == 'blog_preview_title') {
            setPreviewTitle(e.target.value)
        }
        if(e.target.name == 'blog_author') {
            setAuthor(e.target.value)
        }
        if(e.target.name == 'blog_preview_image') {
            setImage(e.target.files[0].name)
            setImageFile(e.target.files[0])
            convertFileToBase64(e.target.files[0])
        }
        if(e.target.name == 'blog_preview_text') {
            setPreviewText(e.target.value)
        }
        if(e.target.name === 'date') {
            setDate(e.target.value)
            console.log(e.target.value)
        }
    }


    const onSubmit = (e) => {
        e.preventDefault()

        //dispatch(getSignedRequest(imageFile))


        const data = {
            blog_title: blogTitle,
            blog_author: author,
            blog_preview_title: previewTitle,
            blog_preview_image: image,
            blog_preview_text: previewText,
            blog_content: blogContent,
            date: date
        }

        axios.put(`https://nestidd-backend.herokuapp.com/api/update_blog/${props.match.params.id}`, data).then(res => {
            console.log(res.data)
            setHasUpdated(true)
        })
    }

    const addAlert = () => {
        if(hasUpdated){
            return <Alert color="success">
                Blog has been Updated!
            </Alert>
        }
    }

    return (
        <div className={'blog-editor'}>
            <Container>
                <Row>
                    <Col md={12}>
                        {addAlert()}
                    </Col>
                    <Col md={12}>
                        <Form onSubmit={onSubmit}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Blog Title</Label>
                                        <Input onChange={handleChange} value={blogTitle} name={'blog_title'}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Author</Label>
                                        <Input onChange={handleChange} value={author} name={'blog_author'}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Preview Title</Label>
                                        <Input onChange={handleChange} value={previewTitle} name={'blog_preview_title'}/>
                                    </FormGroup>


                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Preview Text</Label>
                                        <Input onChange={handleChange} value={previewText} name={'blog_preview_text'}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleDate">Date</Label>
                                        <Input
                                            onChange={handleChange}
                                            value={date}
                                            type="date"
                                            name="date"
                                            id="exampleDate"
                                            placeholder="date"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'d-flex'}>

                                    <div className={'mr-3'}>
                                        <img style={{maxWidth:'250px'}} src={image64} alt=""/>
                                    </div>
                                    <div>
                                        <Label>Preview Image</Label>

                                        <Input type={'file'} onChange={handleChange} name={'blog_preview_image'}/>
                                    </div>
                                </FormGroup>
                                    <FormGroup className={'d-flex'}>
                                        <div className={'mr-2'}>
                                            <BlogPreview blogTitle={blogTitle} blogWriter={author} blogDate={'July 29, 2020'} blogContent={blogContent}/>

                                        </div>
                                        <Button style={{background: '#003F67'}} type={'submit'}>
                                            Save
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                    <Col md={12}>
                        {SetBlogContent()}
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default EditBlog;