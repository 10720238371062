import React from 'react';
import {Card,CardBody} from 'reactstrap';

const ChmCard = ({cardImage, cardText}) => {
    return (
        <Card className={'chm-card'}>
            <CardBody>
                <img src={cardImage} alt=""/>
                {cardText}
            </CardBody>
        </Card>
    );
};

export default ChmCard;