import React, {Fragment, useEffect, useState} from 'react';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap'
import SideBlogPreview from "./SideBlogPreview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from "react-redux";
import {getAllBlogs} from "../../actions/general/generalActions";

const LatestNewsBlogs = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [search, setSearch] = useState('')
    const [filterBlogs, setFilteredBlog] = useState([])
    const [allBlogs, setAllBlogs] = useState([])

    useEffect(()=>{
        dispatch(getAllBlogs())
        if(filterBlogs.length == 0) {
            setFilteredBlog(general.allBlogs)
        }
    }, [])

    useEffect(()=>{
        setFilteredBlog(general.allBlogs)
        setAllBlogs(general.allBlogs)

    })

    const onChange = (e) => {
        if(e.target.name == 'search') {
            setSearch(e.target.value.toString().toLowerCase())
        }
    }


    console.log(search)

    const searchFilter = general.allBlogs.filter(
        blog => {
            return blog.blog_title.toLowerCase().includes(search)
        }
    )

    return (
        <div className={'side-blog-preview-wrapper'}>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <i>
                        <FontAwesomeIcon icon="search" />
                    </i>
                </InputGroupAddon>
                <Input onChange={onChange} name={'search'} placeholder={'Search'} />
            </InputGroup>
            <div className={'side-blog-header'}>
                <h1>
                    Latest News
                </h1>
                <hr/>
            </div>
            {
               searchFilter.map((blog, index) => (
                   <Fragment>
                       {
                           index < 3 ? (
                               <Fragment>
                                   <SideBlogPreview id={blog._id.$oid} blogTitle={blog.blog_title} date={blog.blog_preview_date} content={blog.blog_preview_text}/>
                                   <hr/>
                               </Fragment>
                           ) : null
                       }


                   </Fragment>
               ))
            }
        </div>
    );
};

export default LatestNewsBlogs;