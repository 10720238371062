import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {deleteTeamMemeber, setCarouselActive, setToggle} from "../actions/general/generalActions";

const SetCarouselActiveModal = ({buttonLabel, className, id}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const setActive = () => {
        dispatch(setCarouselActive(id)).then(()=>{
            dispatch(setToggle('toggle'))
            setModal(!modal)
        })

    }

    return (
        <div>
            <Button className={'publish-button'} onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    Are you sure you want to active?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={setActive}>Yes</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default SetCarouselActiveModal;