import React, {Fragment, useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Input,
    Label,
    InputGroup,
    FormGroup
} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {createNewUser, editUser, setToggle} from "../actions/general/generalActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UpdateUsersModal = ({className, fName, lName, mail, uName, pos, aLevel, pWord, id}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [position, setPosition] = useState('')
    const [accessLevel, setAccessLevel] = useState('admin')
    const [password, setPassword] = useState('')

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        setFirstName(fName)
        setLastName(lName)
        setEmail(mail)
        setUsername(uName)
        setPosition(pos)
        setAccessLevel(aLevel)
    }, [])


    const onChange = (e) => {
        if (e.target.name == 'first_name') {
            setFirstName(e.target.value)
        }
        if (e.target.name == 'last_name') {
            setLastName(e.target.value)
        }
        if (e.target.name == 'email') {
            setEmail(e.target.value)
        }
        if (e.target.name == 'username') {
            setUsername(e.target.value)
        }
        if (e.target.name == 'position') {
            setPosition(e.target.value)
        }
        if (e.target.name == 'access_level') {
            setAccessLevel(e.target.value)
        }
        if (e.target.name == 'password') {
            setPassword(e.target.value)
        }
    }


    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            username: username,
            position: position,
            access_level: accessLevel,
            password: password
        }

        dispatch(editUser(id, data)).then(()=>{
            dispatch(setToggle(''))
        })
        setModal(!modal)
    }


    return (
        <Fragment>
            <Button className={'p-0'} style={{background: 'none', border: 'none'}}  onClick={toggle}>
                <i style={{color: '#003F67', fontSize: '25px', lineHeight: '0'}}>
                    <FontAwesomeIcon icon={'edit'}/>
                </i>

            </Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <Form onSubmit={onSubmit}>
                    <ModalHeader toggle={toggle}>Edit User</ModalHeader>
                    <ModalBody>
                        <InputGroup className={'mb-3'}>
                            <InputGroup style={{width: '45%'}} className={'d-flex flex-column w-unset mr-3'}>
                                <Label>
                                    First Name
                                </Label>
                                <Input className={'w-100'} value={firstName} onChange={onChange} name={'first_name'}
                                       placeholder={'First Name'}/>
                            </InputGroup>
                            <InputGroup style={{width: '50%'}} className={'d-flex flex-column'}>
                                <Label>
                                    Last Name
                                </Label>
                                <Input className={'w-100'} value={lastName} onChange={onChange} name={'last_name'}
                                       placeholder={'Last Name'}/>
                            </InputGroup>
                        </InputGroup>
                        <InputGroup className={'mb-3'}>
                            <InputGroup style={{width: '45%'}} className={'d-flex flex-column w-unset mr-3'}>
                                <Label>
                                    Email
                                </Label>
                                <Input className={'w-100'} value={email} onChange={onChange} name={'email'}
                                       placeholder={'Email'}/>
                            </InputGroup>
                            <InputGroup style={{width: '50%'}} className={'d-flex flex-column w-unset '}>
                                <Label>
                                    Username
                                </Label>
                                <Input className={'w-100'} value={username} onChange={onChange} name={'username'}
                                       placeholder={'Username'}/>
                            </InputGroup>
                        </InputGroup>
                        <InputGroup className={'mb-3'}>
                            <InputGroup style={{width: '45%'}} className={'d-flex flex-column w-unset mr-3'}>
                                <Label>
                                    Position
                                </Label>
                                <Input className={'w-100'} value={position} onChange={onChange} name={'position'}
                                       placeholder={'Position'}/>
                            </InputGroup>
                            <InputGroup style={{width: '50%'}} className={'d-flex flex-column w-unset'}>
                                <Label>
                                    Access Level
                                </Label>
                                <Input className={'w-100'} value={accessLevel} onChange={onChange} name={'access_level'}
                                       placeholder={'Access Level'}/>
                            </InputGroup>
                        </InputGroup>
                        <InputGroup className={'d-flex flex-column'}>
                            <Label>
                                Password
                            </Label>
                            <Input className={'w-100'} value={password} onChange={onChange} name={'password'}
                                   placeholder={'Password'}/>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type={'submit'} style={{background: '#003F67'}}>Save</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default UpdateUsersModal;