import React, {useEffect} from 'react';
import { Table } from 'reactstrap';
import NewUserModal from "../../../components/NewUserModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsers} from "../../../actions/general/generalActions";
import Container from "reactstrap/es/Container";
import DeleteUserModal from "../../../components/DeleteUserModal";
import UserEditProfile from "./UserEditProfile";
import UpdateUsersModal from "../../../components/UpdateUsersModal";

const UsersList = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    useEffect(() =>{
        dispatch(getAllUsers())
    }, [general.updateToggle])

    console.log(general.allUsers)


    return (
        <div>
            <NewUserModal/>
            <Container>
                <Table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Access Level</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        general.allUsers.map((user, index) =>(
                            user.access_level !== 'admin-dev' ? (<tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{user.first_name} {user.last_name}</td>
                                    <td>{user.position}</td>
                                    <td>{user.access_level}</td>
                                    <td>
                                        <UpdateUsersModal
                                            id={user.id}
                                            pos={user.position}
                                            aLevel={user.access_level}
                                            fName={user.first_name}
                                            lName={user.last_name}
                                            mail={user.email}
                                            uName={user.username}
                                        />
                                    </td>
                                    <td><DeleteUserModal uid={user.id}/></td>
                                </tr>) :
                                null

                        ))
                    }


                    </tbody>
                </Table>
            </Container>

        </div>
    );
};

export default UsersList;