import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {deleteTeamMemeber} from "../actions/general/generalActions";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DeleteTeamMemberModal = ({buttonLabel, className, uid}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [modal, setModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteTeamMemeber(uid))
        setModal(!modal)
    }

    const toggle = () => setModal(!modal);
    return (
        <div>
            <Button color="danger" onClick={toggle}> <FontAwesomeIcon icon={'times-circle'}/></Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    Are you sure you want to delete?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onDelete}>Yes</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteTeamMemberModal;