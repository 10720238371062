export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS'
export const GET_ACTIVE_TEAM_MEMBERS = 'GET_ACTIVE_TEAM_MEMBERS'
export const GET_TEAM_MEMBER = 'GET_TEAM_MEMBER'
export const RESET_TEAM_MEMBER = 'RESET_TEAM_MEMBER'
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER'
export const SET_TEAM_MEMBER_ACTIVE = 'SET_TEAM_MEMBER_ACTIVE'
export const SET_TEAM_MEMBER_IMAGE = 'SET_TEAM_MEMBER_IMAGE'
export const SET_TEAM_MEMBER_IMAGE_PLACEHOLDER = 'SET_TEAM_MEMBER_IMAGE_PLACEHOLDER'
export const GET_ALL_BLOGS = 'GET_ALL_BLOGS'
export const GET_ALL_ACTIVE_BLOGS = 'GET_ALL_ACTIVE_BLOGS'
export const GET_BLOG = 'GET_BLOG'
export const RESET_BLOG = 'RESET_BLOG'
export const GET_FILE = 'GET_FILE'
export const CHECK_FOR_FILE = 'CHECK_FOR_FILE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const CREATE_NEW_USER = 'CREATE_NEW_USER'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_CAROUSELS = 'GET_ALL_CAROUSELS'
export const GET_ALL_ACTIVE_CAROUSELS = 'GET_ALL_ACTIVE_CAROUSELS'
export const SET_UPDATE_TOGGLE = 'SET_UPDATE_TOGGLE'
export const SET_BLOG_COUNT = 'SET_BLOG_COUNT'
export const GET_ALL_TESTIMONIALS = 'GET_ALL_TESTIMONIALS'
export const GET_TESTIMONIAL = 'GET_TESTIMONIAL'