import React, {Fragment, useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import MainTopNav from "./Navbars/MainTopNav";
import MainFooter from "./Footers/MainFooter";
import CacheBuster from "./CacheBuster";

const NonAuthLayout = (props) => {
    const capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    }

    useEffect(()=> {
        let currentPage = capitalizeFirstLetter(props.location.pathname);
        document.title = `${currentPage} | Nestidd`;
    })

    console.log(props.match)

    return (
        <Fragment>
            <div className={'App'}>
                <CacheBuster/>
                <MainTopNav path={props.match.url}/>
                {props.children}
                <MainFooter path={props.match.url}/>
            </div>

        </Fragment>
    );
};

export default (withRouter(NonAuthLayout));