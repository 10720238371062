import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from "react-redux";
import {getBlog} from "../../actions/general/generalActions";

const BlogCard = ({blogTitle, staffDate, previewText, blogImage, id, blogLink}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    const updateCurrentBlog = () => {
        //dispatch(getBlog(id))
    }

    return (

            <Card className={'blog-card'}>
                <NavLink onClick={updateCurrentBlog} to={blogLink}>
                <CardHeader>
                    <h1 className={'blog-title'}>
                        {blogTitle}
                    </h1>
                    <p className={'blog-writer-date'}>
                        {staffDate}
                    </p>
                    <p className={'latest-news'}>
                        Latest News
                    </p>
                </CardHeader>
                <CardBody>
                    <p className={'preview-text'}>
                        {previewText}
                    </p>
                    <div className={'image-wrapper'}>
                        <div className={'image-overlay d-flex'}>
                            <i className={'m-auto'}>
                                <FontAwesomeIcon icon="link" />
                            </i>
                        </div>

                        {blogImage == '' || blogImage == undefined ? null:
                            ( <img src={`https://nestidd-assets.s3.us-east-2.amazonaws.com/${blogImage}`} alt=""/>)
                        }

                    </div>

                </CardBody>
                </NavLink>
            </Card>


    );
};

export default BlogCard;