import React from 'react';
import {NavLink} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import BlogCard from "../../../components/cards/BlogCard";
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";

const Dsp = () => {
    return (
        <div className={'latest-news-page'}>
            <Container fluid style={{width:' 90%'}}>
                <Row>
                    <Col md={12}>
                        <h1 className={'m-0 page-title'}>
                            Thank a DSP
                        </h1>
                        <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/1'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-1.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/2'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-2.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/3'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-3.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/4'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-4.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/5'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-5.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/6'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-6.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={4}>
                        <NavLink to={'/thank_a_dsp/postcard/7'}>
                            <img className={'w-100'} src={require('../../../assets/images/postcard-7.jpg')} alt=""/>
                        </NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Dsp;