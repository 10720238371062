import React, {Fragment, useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RoundAvatar from "../../Avatars/RoundAvatar";
import {Button, UncontrolledCollapse} from 'reactstrap'
import {useSelector} from "react-redux";
import NewUserModal from "../../NewUserModal";
import UpdateUserModal from "../../UpdateUserModal";
import CreateNewTestimonialModal from "../../CreateNewTestimonialModal";

const AdminSideNavContent = () => {
    const general = useSelector(state => state.General)
    const [firstName, setFirstName] = useState('')

    useEffect(()=> {
        if (general.user != null) {
            setFirstName(general.user.first_name)
        }

    }, [general.user])

    return (
        <Fragment>
            <div id={'sidebar-menu'}>
                <div className={'sidebar-logo text-center'}>
                    <img src={require('../../../assets/images/nestid_logo.png')} alt=""/>
                </div>
                <div className={'sidebar-user'}>
                    <div className={'image-name-wrapper d-flex flex-row'}>
                            <RoundAvatar userImage={'user_placeholder.jpg'}/>
                            <div className={'welcome-name-wrapper'}>
                                <p className={'m-0 welcome'}>Welcome</p>
                                <strong><p className={'m-0 name'}>{firstName}</p></strong>
                            </div>

                    </div>


                </div>
                <ul>
                    <li>
                        <NavLink to={'/admin/dashboard'} activeClassName={'active'}>
                            <Button className={'main-nav-button'} color="primary" id="toggler"
                                    style={{marginBottom: '1rem'}}>
                                <div>
                                    Dashboard
                                </div>
                            </Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/admin/team_members'}>
                        <Button color="primary" id="myLearningDropdown" style={{marginBottom: '1rem'}}>
                            <div>
                                Team Members
                            </div>
                        </Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/admin/blogs'}>
                        <Button color="primary" id="blogsDropdown" style={{marginBottom: '1rem'}}>
                            <div>
                                 Blogs
                            </div>

                        </Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/admin/sliders'}>
                        <Button color="primary" id="slidersDropdown" style={{marginBottom: '1rem'}}>
                            <div>
                                 Sliders
                            </div>

                        </Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={'/admin/testimonials'}>
                        <Button color="primary" id="testimonialsDropdown" style={{marginBottom: '1rem'}}>
                            <div>
                                 Testimonials
                            </div>

                        </Button>
                        </NavLink>
                    </li>
                    <li>
                        <Button color="primary" id="accountsDropdown" style={{marginBottom: '1rem'}}>
                            <div>
                                Account
                            </div>

                        </Button>
                        <UncontrolledCollapse toggler="#accountsDropdown">
                            <ul>
                                <li>
                                    <UpdateUserModal/>
                                </li>
                                <li>
                                    <NavLink to={'/admin/users'}>
                                        <Button color="primary" style={{marginBottom: '1rem'}}>
                                            <div>
                                                View All Users
                                            </div>
                                        </Button>
                                    </NavLink>
                                </li>
                            </ul>
                        </UncontrolledCollapse>
                    </li>



                </ul>
            </div>
        </Fragment>
    );
};

export default AdminSideNavContent;