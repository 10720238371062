import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {
    deleteTeamMemeber,
    getTeamMember,
    getTeamMembers, resetTeamMember, setTeamMemberImage,
    setTeamMemberImagePlaceholder
} from "../../../actions/general/generalActions";
import {Container, Row, Col, Input, Button, Label, CardHeader, Card} from 'reactstrap'
import ProfileCards from "../../../components/cards/ProfileCards";
import DeleteTeamMemberModal from "../../../components/DeleteTeamMemberModal";
import SetUserActiveModal from "../../../components/SetUserActiveModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SetCarouselOrderModal from "../../../components/SetCarouselOrderModal";
import SetTeamOrderModal from "../../../components/SetTeamOrderModal";

const TeamMembersView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [search, setSearch] = useState('')
    const [teamCount, setTeamCount] = useState('')
    const [searchKeys, setSearchKeys] = useState(['first_name', 'last_name', 'position'])
    const [hasUpdated, setHasUpdated] = useState('')

    useEffect(() => {
        dispatch(getTeamMembers())
        dispatch(resetTeamMember())
        dispatch(setTeamMemberImagePlaceholder(''))
        dispatch(setTeamMemberImage(''))
    }, [general.teamCount, general.updateToggle])

    console.log(general.teamCount)
    const onDelete = (id) => () => {
        dispatch(deleteTeamMemeber(id))
    }

    const onChange = (e) => {
        if (e.target.name == 'search') {
            setSearch(e.target.value.toString().toLowerCase())
        }
    }


    const options = () => {
        let optionsList = []
        for (let i = 0; i < general.teamCount; i++) {
            optionsList.push(<option>{i + 1}</option>)
        }
        return optionsList
    }

    const searchFilter = general.teamMembers.filter(
        item => {
            if (search.length == 0) {
                return (
                    item.first_name.toLowerCase().includes(search)
                )
            } else if (search.length > 0) {
                if (item.first_name.toLowerCase().includes(search)) {
                    return (

                        item.first_name.toLowerCase().includes(search)
                    )
                } else if (item.last_name.toLowerCase().includes(search)) {
                    return (

                        item.last_name.toLowerCase().includes(search)
                    )
                } else if (item.position.toLowerCase().includes(search)) {
                    return (
                        item.position.toLowerCase().includes(search)
                    )
                }

            }
        }
    )

    return (
        <div className={'team_members'}>
            <Container>
                <Row>
                    <Col className={'mb-3'} md={12}>
                        <div className={'input-wrapper d-flex'}>
                            <div className={'ml-auto mb-auto mt-auto'}>
                                <Input onChange={onChange} name={'search'} placeholder={'Search...'}/>
                            </div>
                            <div className={'ml-auto'}>
                                <NavLink className={'create-new-team-member-button'} to={'/admin/team_members/create'}>
                                    <FontAwesomeIcon icon={'plus-circle'}/>
                                </NavLink>

                            </div>
                        </div>
                    </Col>
                    {searchFilter.map((teamMember, index) => (
                        <Col md={4} className={'mb-5'}>
                            <div className={'mb-2'}>
                                <div className={'d-flex justify-content-center'}>
                                    <Label className={'mt-auto mb-auto mr-2'}>
                                        Order:
                                    </Label>
                                    <SetTeamOrderModal id={teamMember._id.$oid} currentOrderNumber={teamMember.sort_order}/>
                                </div>
                            </div>
                            <ProfileCards height={'unset'} image={teamMember.image}
                                          name={`${teamMember.first_name} ${teamMember.last_name}`}
                                          position={teamMember.position} link={teamMember.link}/>
                            <div className={'text-center mt-3 d-flex justify-content-around'}>

                                <NavLink to={`/admin/team_members/${teamMember._id.$oid}`}>
                                    <Button style={{background: '#003F67'}} className={''}>
                                        Edit
                                    </Button>
                                </NavLink>
                                {teamMember.active == undefined || teamMember.active == null || teamMember.active == false ?
                                    <SetUserActiveModal uid={teamMember._id.$oid} buttonLabel={'Activate'}/>
                                    : <SetUserActiveModal uid={teamMember._id.$oid} buttonLabel={'Deactivate'}/>
                                }
                                <DeleteTeamMemberModal uid={teamMember._id.$oid}/>
                            </div>
                        </Col>
                    ))}

                </Row>
            </Container>
        </div>
    );
};

export default TeamMembersView;