import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Container, Row, Col, Form, FormGroup, Input, Label, InputGroup, Button, Alert} from 'reactstrap'
import ProfileCards from "../../../components/cards/ProfileCards";
import {
    createTeamMember,
    getSignedRequest,
    getTeamMember,
    getTeamMembers,
    editTeamMember, setToggle, setTeamMemberImage
} from "../../../actions/general/generalActions";
import axios from "axios";
import ImagerCropper from "../../../components/ImagerCropper";


const TeamMemberView = (props) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [position, setPosition] = useState('')
    const [link, setLink] = useState('')
    const [photo, setPhoto] = useState('')
    const [imageFile, setImageFile] = useState('')
    const [hasReceivedData, setHasReceivedDate] = useState(false)
    const [hasUpdated, setHasUpdated] = useState(false)

    useEffect(()=>{
        dispatch(getTeamMember(props.match.params.id))
        if(general.teamMemberImagePlaceholder !== '') {
            setPhoto(general.teamMemberImagePlaceholder)
        }
    }, [general.updateToggle])

    useEffect(()=>{
        if(general.teamMember[0] !== undefined) {
            if(hasReceivedData === false) {
                setFirstName(general.teamMember[0].first_name)
                setLastName(general.teamMember[0].last_name)
                setLink(general.teamMember[0].link)
                console.log(general.teamMember[0].link)
                setPosition(general.teamMember[0].position)
                setPhoto(general.teamMember[0].image)
                dispatch(setTeamMemberImage(general.teamMember[0].image.split('/')[3]))
                setHasReceivedDate(true)
                dispatch(setToggle('s'))
            }
        }
    })

    console.log(general.teamMember[0])

    if(hasReceivedData === false){

    }

    const handleChange = (e) => {
        if(e.target.name == 'firstName') {
            setFirstName(e.target.value)
        }
        if(e.target.name == 'lastName') {
            setLastName(e.target.value)
        }
        if(e.target.name == 'position') {
            setPosition(e.target.value)
        }
        if(e.target.name == 'link') {
            setLink(e.target.value)
        }
        if(e.target.name == 'photo') {
            // setPhoto(e.target.files[0].name)
            // setImageFile(e.target.files[0])
        }

    }

    const onSubmit = (e) => {
        e.preventDefault()

        const teamMember = {
            first_name: firstName,
            last_name: lastName,
            position: position,
            link: link,
            image: general.teamMemberImage
        }

       dispatch(editTeamMember(teamMember, props.match.params.id)).then(()=>{
           setHasUpdated(true)
       })
    }

    const addAlert = () => {
        if(hasUpdated){
            return <Alert color="success">
                {firstName} {lastName} has been updated!
            </Alert>
        }
    }

    const setForm = () => {
        if(hasReceivedData) {
            return (<Form>
                <FormGroup className={'d-flex'}>
                    <InputGroup className={'d-block'}>
                        <Label>
                            First Name
                        </Label>
                        <Input onChange={handleChange} value={firstName} name={'firstName'} className={'w-100'}/>
                    </InputGroup>
                    <InputGroup className={'d-block'}>
                        <Label>
                            Last Name
                        </Label>
                        <Input onChange={handleChange} value={lastName} name={'lastName'} className={'w-100'}/>
                    </InputGroup>
                </FormGroup>

                <FormGroup className={'d-flex'}>
                    <InputGroup className={'d-block'}>
                        <Label>
                            Position
                        </Label>
                        <Input onChange={handleChange} value={position} name={'position'} className={'w-100'}/>
                    </InputGroup>
                </FormGroup>
                <FormGroup className={'d-flex'}>
                    <InputGroup className={'d-block'}>
                        <Label>
                            LinkedIn Link
                        </Label>
                        <Input onChange={handleChange} value={link} name={'link'} className={'w-100'}/>
                    </InputGroup>
                </FormGroup>
                <div className={'d-flex'}>
                    <div className={'mr-3'}>
                        <ImagerCropper/>
                    </div>

                    <Button style={{background: '#003F67'}} onClick={onSubmit}>
                        Save
                    </Button>
                </div>

            </Form>)
        }
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col md={12}>
                        {addAlert()}
                    </Col>
                    <Col md={4}>
                        <ProfileCards isPlaceholder={true} name={`${firstName} ${lastName}`} position={position} image={photo} />
                    </Col>
                    <Col md={8}>
                        {setForm()}
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default TeamMemberView;