import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap'
import SalesLeasebackCard from "../../../components/cards/SalesLeasebackCard";

const bgImage = require('../../../assets/images/grid_gradient.png')

const SalesLeasebackSection = () => {
    return (
        <section id={'sales-leaseback'} className={'sales-leaseback-section'} style={{backgroundImage: `url(${bgImage})`}}>
            <div className={'text-center mb-5 header-section'}>
                <h1>
                    Sale / Leaseback
                </h1>
                <img src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
            </div>
            <div className={''} >
                <Container>
                    <Row>
                        <Col className={'mb-4'} md={3}>
                            <SalesLeasebackCard cardColor={'#003F67'} cardTitle={'STEP 1'} cardText={'Provide us with the property address '} cardImage={require('../../../assets/images/slc_step1.svg')}/>
                        </Col>
                        <Col className={'mb-4'} md={3}>
                            <SalesLeasebackCard cardColor={'#09409B'} cardTitle={'STEP 2'} cardText={'We will send a price and lease proposal within 2 days'} cardImage={require('../../../assets/images/slc_step2.svg')}/>
                        </Col>
                        <Col className={'mb-4'} md={3}>
                            <SalesLeasebackCard cardColor={'#EE7D33'} cardTitle={'STEP 3'} cardText={'Execute purchase /lease agreement.'} cardImage={require('../../../assets/images/slc_step3.svg')}/>
                        </Col>
                        <Col className={'mb-4'} md={3}>
                            <SalesLeasebackCard cardColor={'#91E2D0'} cardTitle={'STEP 4'} cardText={'Nestidd will close on the property within 10 days of executed agreement.'} cardImage={require('../../../assets/images/slc_step4.svg')}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default SalesLeasebackSection;