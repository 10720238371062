import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col, Form, FormGroup, InputGroup, Input, Label, Button, Alert} from 'reactstrap'
import axios from 'axios'
import {getSignedRequest, getTeamMembers, setFile, uploadFiles} from "../../../actions/general/generalActions";
import BlogPreview from "./BlogPreview";
import {Editor} from "@tinymce/tinymce-react";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";


const CreateBlog = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [blogTitle, setBlogTitle] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [previewText, setPreviewText] = useState('')
    const [author, setAuthor] = useState('')
    const [image, setImage] = useState('')
    const [blogContent, setBlogContent] = useState('')
    const [blogImage, setBlogImage] = useState(general.imageUpload)
    const [imageFile, setImageFile] = useState('')
    const [hasUpdated, setHasUpdated] = useState(false)
    const [date, setDate] = useState('')
    const [image64, setImage64] = useState('')


    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
        setBlogContent(content)
    }

    const onFileChange = e => {
        setImage(e.target.files[0].name)
        setImageFile(e.target.files[0])
        convertFileToBase64(e.target.files[0])
    }

    const onChange = e => {
        if (e.target.name === 'blog_title') {
            setBlogTitle(e.target.value)
        }
        if (e.target.name === 'blog_preview_title') {
            setPreviewTitle(e.target.value)
        }
        if (e.target.name === 'blog_author') {
            setAuthor(e.target.value)
        }
        if (e.target.name === 'blog_preview_image') {

        }
        if (e.target.name === 'blog_preview_text') {
            setPreviewText(e.target.value)
        }
        if (e.target.name === 'date') {
            setDate(e.target.value)
        }
    }


    const convertFileToBase64 = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            setImage64(reader.result)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (image !== '') {
            dispatch(getSignedRequest(imageFile))
        }


        const data = {
            blog_title: blogTitle,
            blog_author: author,
            blog_preview_title: previewTitle,
            blog_preview_image: image,
            blog_preview_text: previewText,
            blog_content: blogContent,
            date: date
        }

        axios.post('https://nestidd-backend.herokuapp.com/api/create_blog', data).then(res => {
            console.log(res.data)
            setHasUpdated(true)
        })
    }

    const addAlert = () => {
        if (hasUpdated) {
            return <Alert color="success">
                New Blog has been Created!
            </Alert>
        }
    }

    return (
        <div className={'blog-editor'}>
            <Container>
                <Row>
                    <Col md={12}>
                        {addAlert()}
                    </Col>
                    <Col md={12}>
                        <Form onSubmit={onSubmit}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Blog Title</Label>
                                        <Input onChange={onChange} name={'blog_title'}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Author</Label>
                                        <Input onChange={onChange} name={'blog_author'}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Preview Title</Label>
                                        <Input onChange={onChange} name={'blog_preview_title'}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Preview Text</Label>
                                        <Input onChange={onChange} name={'blog_preview_text'}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleDate">Date</Label>
                                        <Input
                                            onChange={onChange}
                                            type="date"
                                            name="date"
                                            id="exampleDate"
                                            placeholder="date"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'d-flex'}>

                                        <div className={'mr-3'}>
                                            <img style={{maxWidth: '250px'}} src={image64} alt=""/>
                                        </div>
                                        <div>
                                            <Label>Preview Image</Label>
                                            <Input type={'file'} onChange={onFileChange} name={'blog_preview_image'}/>

                                        </div>
                                    </FormGroup>
                                    {/*<FormGroup>*/}
                                    {/*    <Input onChange={onChange} name={'blog_content'} value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}/>*/}
                                    {/*</FormGroup>*/}
                                    <FormGroup className={'d-flex'}>
                                        <div className={'mr-2'}>
                                            <BlogPreview blogTitle={blogTitle} blogWriter={author}
                                                         blogDate={'July 29, 2020'} blogContent={blogContent}/>
                                        </div>
                                        <Button style={{background: '#003F67'}} type={'submit'}>
                                            Save
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={12}>
                        <Editor
                            apiKey='ewwrgr426zy6sptaah1255v6yyqp01jgen6yi8umxvqqalkd'
                            initialValue=''
                            init={{
                                height: 500,
                                menubar: false,
                                branding: false,
                                plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable',
                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                                image_advtab: true,

                                /* we override default upload handler to simulate successful upload*/
                                images_upload_handler: function (blobInfo, success, failure) {

                                },

                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default CreateBlog;