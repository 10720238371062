import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from 'react-router-dom'

import {Alert, Container, Row, Col, Form, FormGroup, Input, Label, InputGroup, Button} from 'reactstrap'
import ProfileCards from "../../../components/cards/ProfileCards";
import {
    createTeamMember,
    getSignedRequest,
    getTeamMember,
    getTeamMembers
} from "../../../actions/general/generalActions";
import axios from "axios";
import ImagerCropper from "../../../components/ImagerCropper";


const CreateTeamMemberView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [position, setPosition] = useState('')
    const [link, setLink] = useState('')
    const [photo, setPhoto] = useState('')
    const [imageFile, setImageFile] = useState('')
    const [hasCreated, setHasCreated] = useState(false)
    const [redirect, setRedirect] = useState(false)

    useEffect(()=>{

        if(general.teamMemberImagePlaceholder !== '') {
            setPhoto(general.teamMemberImagePlaceholder)
        }

    }, [general.teamMemberImagePlaceholder])

    const handleChange = (e) => {
        if(e.target.name == 'firstName') {
            setFirstName(e.target.value)
        }
        if(e.target.name == 'lastName') {
            setLastName(e.target.value)
        }
        if(e.target.name == 'position') {
            setPosition(e.target.value)
        }
        if(e.target.name == 'link') {
            setLink(e.target.value)
        }
        if(e.target.name == 'photo') {
            setPhoto(e.target.files[0].name)
            setImageFile(e.target.files[0])
        }

    }

    if(redirect) {
        return <Redirect to={'/admin/team_members'}/>
    }

    const onSubmit = (e) => {

        const teamMember = {
            first_name: firstName,
            last_name: lastName,
            position: position,
            link: link,
            image: general.teamMemberImage
        }

       dispatch(createTeamMember(teamMember)).then(()=> {
           setHasCreated(true)

           setTimeout(()=> {
               console.log('yes')
               setRedirect(true)
           }, 2000)
       })
    }

    const addAlert = () => {
        if(hasCreated){
            return <Alert color="success">
                {firstName} {lastName} has been created!
                </Alert>
        }
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col md={12}>
                        {addAlert()}
                    </Col>
                    <Col md={4}>
                        <ProfileCards name={`${firstName} ${lastName}`} position={position} image={photo} />
                    </Col>
                    <Col md={8}>
                        <Form>
                            <FormGroup className={'d-flex'}>
                                <InputGroup className={'d-block'}>
                                    <Label>
                                        First Name
                                    </Label>
                                    <Input onChange={handleChange} value={firstName} name={'firstName'} className={'w-100'}/>
                                </InputGroup>
                                <InputGroup className={'d-block'}>
                                    <Label>
                                        Last Name
                                    </Label>
                                    <Input onChange={handleChange} value={lastName} name={'lastName'} className={'w-100'}/>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className={'d-flex'}>
                                <InputGroup className={'d-block'}>
                                    <Label>
                                        Position
                                    </Label>
                                    <Input onChange={handleChange} value={position} name={'position'} className={'w-100'}/>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className={'d-flex'}>
                                <InputGroup className={'d-block'}>
                                    <Label>
                                        LinkedIn Link
                                    </Label>
                                    <Input onChange={handleChange} value={link} name={'link'} className={'w-100'}/>
                                </InputGroup>
                            </FormGroup>
                            <div className={'d-flex'}>
                                <div className={'mr-3'}>
                                    <ImagerCropper/>
                                </div>

                                <Button style={{background: '#003F67'}} onClick={onSubmit}>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default CreateTeamMemberView;