import React from 'react';
import {Container, Row, Col, Form, FormGroup, Input, Label, InputGroup, Button} from 'reactstrap'

const UserEditProfile = () => {
    return (
        <div>
            <Form>
                <FormGroup>
                    <InputGroup>
                        <Label>First Name</Label>
                        <Input/>
                    </InputGroup>
                    <InputGroup>
                        <Label>Last Name</Label>
                        <Input/>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup>
                        <Label>Username</Label>
                        <Input/>
                    </InputGroup>
                    <InputGroup>
                        <Label>Email</Label>
                        <Input/>
                    </InputGroup>
                </FormGroup>

            </Form>
        </div>
    );
};

export default UserEditProfile;