import {
    AUTH_ERROR,
    DELETE_TEAM_MEMBER,
    GET_ACTIVE_TEAM_MEMBERS,
    GET_ALL_ACTIVE_BLOGS,
    GET_ALL_ACTIVE_CAROUSELS,
    GET_ALL_BLOGS,
    GET_ALL_CAROUSELS,
    GET_ALL_USERS,
    GET_BLOG,
    GET_FILE,
    GET_TEAM_MEMBER,
    GET_TEAM_MEMBERS,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_BLOG_COUNT,
    SET_UPDATE_TOGGLE,
    SET_TEAM_MEMBER_IMAGE,
    SET_TEAM_MEMBER_IMAGE_PLACEHOLDER,
    USER_LOADED, GET_ALL_TESTIMONIALS, GET_TESTIMONIAL, RESET_TEAM_MEMBER, RESET_BLOG
} from "../actions/general/actionTypes";


const initialState = {
    token: localStorage.getItem('token'),
    user: null,
    isAuthenticated: false,
    teamMembers: [],
    activeTeamMembers: [],
    teamMember: {},
    teamMemberImage: '',
    teamMemberImagePlaceholder: '',
    allBlogs: [],
    allActiveBlogs: [],
    currentBlog: [],
    imageUpload: '',
    teamCount: 0,
    allUsers: [],
    allCarousels: [],
    allActiveCarousels: [],
    updateToggle: false,
    blogCount: 0,
    allTestimonials: [],
    testimonial: ''
}

const General = (state = initialState, action) => {
    switch (action.type) {
        case RESET_BLOG:
            return {
                ...state,
                currentBlog: initialState.currentBlog
            }
        case RESET_TEAM_MEMBER:
            return {
                ...state,
                teamMember: initialState.teamMember
            }
        case GET_TESTIMONIAL:
            return {
                ...state,
                testimonial: action.payload
            }
        case GET_ALL_TESTIMONIALS:
            return {
                ...state,
                allTestimonials: action.payload
            }
        case SET_UPDATE_TOGGLE:
            return {
                ...state,
                updateToggle: !state.updateToggle
            }
        case GET_ALL_CAROUSELS:
            return {
                ...state,
                allCarousels: action.payload
            }
        case GET_ALL_ACTIVE_CAROUSELS:
            return {
                ...state,
                allActiveCarousels: action.payload
            }

        case SET_TEAM_MEMBER_IMAGE:
            return {
                ...state,
                teamMemberImage: action.payload
            }

        case SET_TEAM_MEMBER_IMAGE_PLACEHOLDER:
            return {
                ...state,
                teamMemberImagePlaceholder: action.payload
            }

        case GET_ALL_USERS:
            return  {
                ...state,
                allUsers: action.payload
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            }

        case AUTH_ERROR:
            localStorage.removeItem('token')
            return {
                ...state,
                isAuthenticated: false,
                token: null
            }

        case LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                user: null
            }

        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.access_token)
            return {
                ...state,
                isAuthenticated: true
            }

        case LOGIN_FAIL:
            localStorage.removeItem('token')
            return {
                ...state,
                isAuthenticated: false,
                token: null
            }

        case GET_TEAM_MEMBERS:
            return {
                ...state,
                teamMembers: action.payload,
                teamCount: action.payload.length
            }
        case GET_ACTIVE_TEAM_MEMBERS:
            return {
                ...state,
                activeTeamMembers: action.payload
            }
        case GET_TEAM_MEMBER:
            return {
                ...state,
                teamMember: action.payload
            }
        case DELETE_TEAM_MEMBER:
            return {
                ...state,
                teamCount: state.teamCount - 1
            }
        case SET_BLOG_COUNT:
            return {
                ...state,
                blogCount: state.blogCount - 1
            }
        case GET_ALL_BLOGS:
            return {
                ...state,
                allBlogs: action.payload
            }
        case GET_ALL_ACTIVE_BLOGS:
            return {
                ...state,
                allActiveBlogs: action.payload
            }
        case GET_BLOG:
            return {
                ...state,
                currentBlog: action.payload
            }
        case GET_FILE:
            return {
                ...state,
                imageUpload: action.payload
            }
        default:
            return state
    }
}

export default General;