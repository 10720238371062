import React from 'react';
import {NavLink} from "react-router-dom";

const SideBlogPreview = ({blogTitle, date, content, id}) => {
    return (
        <NavLink  to={`/blog/${id}`}>
        <div className={'side-blog-preview'}>
            <div className={'d-flex'}>
                <h1 className={'blog-title mr-auto'}>
                    {blogTitle}
                </h1>
                <div className={'blog-date d-flex'}>
                    <div className={'m-auto'}>
                        {date}
                    </div>
                </div>
            </div>
            <div>
                <p className={'blog-content'}>
                    {content}
                </p>
            </div>

        </div>
        </NavLink>
    );
};

export default SideBlogPreview;