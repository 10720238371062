import React from 'react';
import {Card,CardBody, CardHeader} from 'reactstrap';

const SalesLeasebackCard = ({cardImage, cardText, cardTitle, cardColor}) => {
    return (
        <Card className={'sales-leaseback-card'}>
            <CardHeader style={{background: cardColor}}>
                {cardTitle}
            </CardHeader>
            <CardBody>
                <img src={cardImage} alt=""/>
                <p>
                    {cardText}
                </p>
            </CardBody>

        </Card>
    );
};

export default SalesLeasebackCard;