import React, {Fragment} from 'react';
import AdminMainHeaderNavContent from "./AdminMainHeaderNavContent";

const AdminMainHeaderNav = () => {
    return (
        <Fragment>
            <AdminMainHeaderNavContent/>
        </Fragment>
    );
};

export default AdminMainHeaderNav;