import React from 'react';
import {Card, CardBody, CardFooter} from 'reactstrap'

const DashboardCards = ({icon, color, title}) => {
    return (
        <Card style={{background: color}} className={'dashboard-card text-center'}>
            <CardBody >
                {icon}
            </CardBody>
            <CardFooter>
                {title}
            </CardFooter>
        </Card>
    );
};

export default DashboardCards;