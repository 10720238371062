import React, {Fragment, useState, useEffect} from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import {pubicRoutes, authProtectedRoutes} from "../../routes";


const Breadcrumbs = ({props, urlPath}) => {
    return (
        <Fragment>
            <Row>
                <Col>
                    <h4>
                        <Breadcrumb className={'breadcrumbs'}>
                            {authProtectedRoutes.map((route, index)=> (

                              (
                                  route.path == urlPath ? (
                                      route.breadCrumbTitleList.map((breadcrumb) => (
                                          <BreadcrumbItem>{breadcrumb}</BreadcrumbItem>
                                      ))
                                  ) : null

                              )
                            ))}

                        </Breadcrumb>
                    </h4>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Breadcrumbs;