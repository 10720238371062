import React, {useState, Fragment} from 'react';
import {NavLink} from 'react-router-dom'
import {Link, animateScroll as scroll} from 'react-scroll'
import Scrollspy from 'react-scrollspy'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText, Button, Container, UncontrolledCollapse
} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const MainTopNav = (path) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onMouseOver = () => {
        setIsDropDownOpen(true)
    }

    const onMouseLeave = () => {
        setIsDropDownOpen(false)
    }

    console.log(path)

    const dropdown = () => {

        if (path.path !== '/home') {
            return <Fragment>
                <a className={'dropdown-item'} href={'/home#simple-leasing'}>
                    Simple Leasing
                </a>

                <a className={'dropdown-item'} href={'/home#community-home-maintenance'}>
                    Nestidd 360
                </a>


                <a className={'dropdown-item'} href={'/home#sales-leaseback'}>
                    Proven Results
                </a>
            </Fragment>
        } else {
            return <Fragment>
                <Link to="simple-leasing" spy={true} smooth={true} offset={50} duration={500}
                      className={'dropdown-item'}>
                    Simple Leasing
                </Link>

                <Link className={'dropdown-item'} to={'community-home-maintenance'} spy={true} smooth={true} offset={0}
                      duration={500}>
                    Nestidd 360
                </Link>

                <Link className={'dropdown-item'} to={'sales-leaseback'} spy={true} smooth={true} offset={50}
                      duration={500}>
                    Proven Results
                </Link>
            </Fragment>
        }
    }

    const leftNavGroup = () => {

        if(window.innerWidth <= 767) {
            return (
                <Fragment>


                    <Scrollspy items={['simple-leasing', 'community-home-maintenance', 'sales-leaseback']}>
                        <div className={'what-we-do-nav'}>
                            <Button className={'w-100 what-we-do-btn'} id="toggler" style={{ marginBottom: '1rem' }}>
                                What We Do
                            </Button>
                            <UncontrolledCollapse toggler="#toggler">
                                {dropdown()}
                            </UncontrolledCollapse>
                        </div>

                        {/*<UncontrolledDropdown >*/}
                        {/*    <DropdownToggle className={'nav-link'} nav>*/}
                        {/*        What We Do*/}
                        {/*    </DropdownToggle>*/}
                        {/*    <DropdownMenu className={'text-center'} left>*/}
                        {/*        {dropdown()}*/}
                        {/*    </DropdownMenu>*/}
                        {/*</UncontrolledDropdown>*/}
                    </Scrollspy>
                    <div className={'about-nav'}>
                        <Button className={'w-100 what-we-do-btn nav-link'} id="toggler1" style={{ marginBottom: '1rem' }}>
                            About
                        </Button>
                        <UncontrolledCollapse toggler="#toggler1">
                            <NavLink className={'dropdown-item'} to={'/who_we_are'}>
                                Who We Are
                            </NavLink>


                            <NavLink className={'dropdown-item'} to={'/leadership'}>
                                Team
                            </NavLink>
                        </UncontrolledCollapse>
                    </div>
                    {/*<UncontrolledDropdown nav inNavbar>*/}
                    {/*    <DropdownToggle nav>*/}
                    {/*        About*/}
                    {/*    </DropdownToggle>*/}
                    {/*    <DropdownMenu className={'text-center '} left>*/}

                    {/*        <NavLink className={'dropdown-item'} to={'/who_we_are'}>*/}
                    {/*            Who We Are*/}
                    {/*        </NavLink>*/}


                    {/*        <NavLink className={'dropdown-item'} to={'/leadership'}>*/}
                    {/*            Team*/}
                    {/*        </NavLink>*/}
                    {/*    </DropdownMenu>*/}
                    {/*</UncontrolledDropdown>*/}
                </Fragment>
            )
        }

        return (
            <Fragment>
                <Scrollspy items={['simple-leasing', 'community-home-maintenance', 'sales-leaseback']}>
                    <UncontrolledDropdown >
                        <a className={'nav-link'} nav>
                            What We Do
                        </a>
                        <DropdownMenu className={'text-center show'} left>
                            {dropdown()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Scrollspy>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                        About
                    </DropdownToggle>
                    <DropdownMenu className={'text-center show'} left>

                        <NavLink className={'dropdown-item'} to={'/who_we_are'}>
                            Who We Are
                        </NavLink>


                        <NavLink className={'dropdown-item'} to={'/leadership'}>
                            Team
                        </NavLink>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Navbar className={'main-top-nav'} expand="md">
                <Container fluid className={'p-0'}>
                    <NavbarBrand className={''} href="/">
                        <NavLink to={'/home'}>
                            <img src={require('../../assets/images/nestid_logo.png')} alt=""/>
                        </NavLink>
                    </NavbarBrand>
                    <Button onClick={toggle} aria-label="Toggle navigation" type="button" className="navbar-toggler">
                        <i>
                            <FontAwesomeIcon icon={'bars'}/>
                        </i>
                    </Button>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="left-button-group" navbar>
                            {leftNavGroup()}
                            <NavItem>
                                <NavLink className={'nav-link'} to={'/blogs'}>News</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={'nav-link'} to={'/contact_us'}>Contact Us</NavLink>
                            </NavItem>

                        </Nav>
                        <Nav className={'right-button-group'} navbar>

                            <NavLink className={'btn'} to={'/find_a_home'}>
                                Find a Home
                            </NavLink>

                            <a target={'_blank'} className={'btn'}
                               href="http://www.nestidd360.com/">
                                Nestidd360°
                            </a>


                            <NavLink className={'btn'} to={'/thank_a_dsp'}>
                                Thank a DSP
                            </NavLink>

                        </Nav>
                    </Collapse>
                </Container>

            </Navbar>
            <div className={'bottom-gradient'}>

            </div>
        </Fragment>
    );
};

export default MainTopNav;