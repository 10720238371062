import React, {useState} from 'react';
import {Form, FormGroup, InputGroup, Input, Label, Button, Alert} from 'reactstrap'
import {useDispatch, useSelector} from "react-redux";
import {contactUs, contactUsSide} from "../actions/general/generalActions";

const ContactFormSideview = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    const [name, setName] = useState('')
    const [organization, setOrganization] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [email, setEmail] = useState('')
    const [state, setState] = useState('')
    const [resAlert, setResAlert] = useState(false)
    const [goodAlert, setGoodAlert] = useState(false)
    const [badAlert, setBadAlert] = useState(false)

    const onChange = (e) => {
        if (e.target.name == 'name') {
            setName(e.target.value)
        }
        if (e.target.name == 'organization') {
            setOrganization(e.target.value)
        }
        if (e.target.name == 'city') {
            setCity(e.target.value)
        }
        if (e.target.name == 'email') {
            setEmail(e.target.value)
        }
        if (e.target.name == 'zip') {
            setZip(e.target.value)
        }
        if (e.target.name == 'state') {
            setState(e.target.value)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            name,
            organization,
            city,
            zip,
            state,
            email

        }

        dispatch(contactUsSide(data)).then(() => {
            setResAlert(true)
            setGoodAlert(true)


            setName('')


            setOrganization('')


            setCity('')


            setEmail('')


            setZip('')


            setState('')


        }).catch((error) => {
            setResAlert(true)
        })
    }

    const addAlert = () => {
        if (resAlert && goodAlert) {
            return <Alert color="success">
                Message Sent
            </Alert>
        } else if (resAlert) {
            return <Alert color="danger">
                Ooops! Something went wrong. Message was not sent!
            </Alert>
        }
    }

    return (
        <div className={'contact-us-sideview'}>
            <div className={'contact-us-header'}>
                <h1>
                    Contact Us
                </h1>
                <hr/>
            </div>
            <div>
                <Form onSubmit={onSubmit} className={'contact-us-sideview-form'}>
                    <FormGroup>
                        <Label>
                            <span className={'asterisk'}>*</span> City, County
                        </Label>
                        <Input required onChange={onChange} name={'city'}/>
                    </FormGroup>
                    <FormGroup className={'d-flex'}>
                        <InputGroup className={'mr-2'}>
                            <Label>
                                <span className={'asterisk'}>*</span> Zip Code(s) (Optional)
                            </Label>
                            <Input required onChange={onChange} name={'zip'}/>
                        </InputGroup>
                        <InputGroup className={'state-input'}>
                            <Label>
                                <span className={'asterisk'}>*</span> State
                            </Label>
                            <Input required onChange={onChange} name={'state'}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            <span className={'asterisk'}>*</span>Full Name
                        </Label>
                        <Input required onChange={onChange} name={'name'}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            <span className={'asterisk'}>*</span> Email
                        </Label>
                        <Input required type={'email'} onChange={onChange} name={'email'}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            <span className={'asterisk'}>*</span> Name of Organization
                        </Label>
                        <Input onChange={onChange} name={'organization'}/>
                    </FormGroup>
                    {addAlert()}
                    <FormGroup>
                        <Button className={'submit-button'}>
                            Submit
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        </div>
    );
};

export default ContactFormSideview;