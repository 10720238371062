import React, {useState} from 'react';
import {Col, Container, Row, Form, FormGroup, InputGroup, Input, Label, Button} from "reactstrap";
import BlogCard from "../../../components/cards/BlogCard";
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";
import {useDispatch, useSelector} from "react-redux";
import {contactUs, thankADsp} from "../../../actions/general/generalActions";

const Postcard = (props) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const imageNumber = props.match.params.postcardID;

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [note, setNote] = useState('')
    const [postcard, setPostcard] = useState(props.match.params.postcardID)

    const onChange = (e) => {
        if(e.target.name == 'name') {
            setName(e.target.value)
        }
        if(e.target.name == 'address') {
            setAddress(e.target.value)
        }
        if(e.target.name == 'city') {
            setCity(e.target.value)
        }
        if(e.target.name == 'state') {
            setState(e.target.value)
        }
        if(e.target.name == 'zip') {
            setZip(e.target.value)
        }
        if(e.target.name == 'note') {
            setNote(e.target.value)
        }
    }

    const onsubmit = e => {
        e.preventDefault()

        const data = {
            name: name,
            address: address,
            city: city,
            state: state,
            zip: zip,
            note: note,
            postcard: postcard
        }

        dispatch(thankADsp(data))
    }

    return (
        <div className={'postcard-page'}>
            <Container fluid style={{width:' 90%'}}>
                <Row>
                    <Col md={12}>
                        <h1 className={'m-0 page-title'}>
                            Complete Your Postcard
                        </h1>
                        <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                    </Col>
                    <Col className={'mb-3'} md={6}>
                        <img className={'w-100'} src={require(`../../../assets/images/postcard-${imageNumber}.jpg`)} alt=""/>
                    </Col>
                    <Col md={6}>
                        <div className={'postcard-wrapper'}>
                            <h1>
                                Step 2: Complete the back of your postcard
                            </h1>
                            <p>
                                Enter your recipient's name and address below.
                            </p>
                            <Form onSubmit={onsubmit}>
                                <FormGroup className={'mb-4'}>
                                    <Input required onChange={onChange} name={'name'} placeholder={'Full Name'}/>
                                </FormGroup>
                                <FormGroup className={'mb-4'}>

                                    <Input required onChange={onChange} name={'address'} placeholder={'Address'}/>
                                </FormGroup>
                                <FormGroup className={'d-flex mb-4'}>
                                   <InputGroup className={'d-flex flex-column mr-3'}>
                                       <Input required onChange={onChange} name={'city'} placeholder={'City'} className={'w-100'}/>
                                   </InputGroup>
                                    <InputGroup className={'d-flex flex-column mr-3'}>
                                        <Input required onChange={onChange} name={'state'} placeholder={'State'} className={'w-100'}/>
                                    </InputGroup>
                                    <InputGroup className={'d-flex flex-column'}>
                                        <Input required onChange={onChange} name={'zip'} placeholder={'Zip / Postal Code'} className={'w-100'}/>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className={'mb-4'}>
                                    <Input required onChange={onChange} name={'note'} placeholder={'Note'} type={'textarea'}/>
                                </FormGroup>
                                <FormGroup className={'d-flex'}>
                                    <Button className={'ml-auto'}>
                                        Submit
                                    </Button>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Postcard;