import React, {useState, useEffect} from 'react';
import {Container, Card, CardFooter, CardBody, CardHeader, Button, Row, Col} from 'reactstrap';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SimpleLeasingCardSection = () => {
    const [size, setSize] = useState([0, 0]);
    useEffect(()=>{
        window.addEventListener("resize", useState)
    }, [])

    const updateSize = () => {
        setSize([window.innerWidth, window.innerHeight]);
    }

    const addChevrons = () => {

    }

    return (
        <section id={'simple-leasing'} className={'simple-leasing-card-section'}>
            <Container>
                <Card>
                    <CardHeader className={'text-center'}>
                        <h1>
                            Simple Leasing
                        </h1>
                        <img src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                    </CardHeader>
                    <CardBody className={'text-center'}>
                        <Row>
                            <Col className={'text-center ml-auto'} md={2}>
                                    <div className={''}>
                                        <img className={' mb-3'} src={require('../../../assets/images/criteria.svg')} alt=""/>
                                    </div>
                                    <div >
                                        <h2>
                                            Criteria
                                        </h2>
                                        <p>
                                            Nestidd sources best available homes based on your search criteria
                                        </p>
                                    </div>
                            </Col>
                            <Col md={1}>
                                <div className={'ml-auto d-flex chevrons'}>
                                    <i className={'chevron-left chevron-top'}>
                                        <FontAwesomeIcon icon={'chevron-right'}/>
                                    </i>
                                    <i className={' chevron-right chevron-bottom'}>
                                        <FontAwesomeIcon icon={'chevron-right'}/>
                                    </i>
                                </div>
                            </Col>
                            <Col className={'text-center'} md={2}>
                                    <div>
                                        <img className={'mb-3'} src={require('../../../assets/images/tour.svg')} alt=""/>
                                    </div>
                                    <div>
                                        <h2>
                                            Tour
                                        </h2>
                                        <p>
                                            Visit your favorite options and select your favorite(s)
                                        </p>
                                    </div>


                            </Col>
                            <Col md={1}>
                                <div className={'ml-auto d-flex chevrons'}>
                                    <i className={'chevron-left chevron-top'}>
                                        <FontAwesomeIcon icon={'chevron-right'}/>
                                    </i>
                                    <i className={' chevron-right chevron-bottom'}>
                                        <FontAwesomeIcon icon={'chevron-right'}/>
                                    </i>
                                </div>
                            </Col>
                            <Col className={'text-center'} md={2}>
                                    <div >
                                        <img className={'mb-3'} src={require('../../../assets/images/rennovations.svg')} alt=""/>
                                    </div>
                                    <div >
                                        <h2>
                                            Renovations
                                        </h2>
                                        <p>
                                            Nestidd updates your home to your specifications
                                        </p>
                                    </div>


                            </Col>
                            <Col md={1}>
                                <div className={'ml-auto d-flex chevrons'}>
                                    <i className={'chevron-left chevron-top'}>
                                        <FontAwesomeIcon icon={'chevron-right'}/>
                                    </i>
                                    <i className={' chevron-right chevron-bottom'}>
                                        <FontAwesomeIcon icon={'chevron-right'}/>
                                    </i>
                                </div>
                            </Col>
                            <Col className={'text-center mr-auto'} md={2}>

                                    <div className={''}>
                                        <img className={' mb-3'} src={require('../../../assets/images/move_in.svg')} alt=""/>
                                    </div>
                                    <div>
                                        <h2>
                                           Move in!
                                        </h2>
                                    </div>


                            </Col>
                        </Row>
                        {/*<img className={'w-100'} src={require('../../../assets/images/simple_leasing_step_img.png')} alt=""/>*/}
                    </CardBody>
                    <CardFooter className={'text-center'}>
                        <NavLink to={'/contact_us'}>
                            <Button>
                                Let's Get Started
                            </Button>
                        </NavLink>
                    </CardFooter>
                </Card>
            </Container>
        </section>
    );
};

export default SimpleLeasingCardSection;