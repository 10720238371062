import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from "react-redux";
import {getBlog, setToggle} from "../../actions/general/generalActions";
import SetUserActiveModal from "../SetUserActiveModal";
import DeleteBlogModal from "../DeleteBlogModal";
import {FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import SetBlogActiveModal from "../SetBlogActiveModal";

const AdminBlogCard = ({blogTitle, staffDate, previewText, blogImage, id, blogLink, active}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    const updateCurrentBlog = () => {
        dispatch(setToggle(!general.updateToggle))
    }


    return (

        <Card className={'admin-blog-card'}>

            <CardHeader>
                <h1 className={'blog-title'}>
                    {blogTitle}
                </h1>
                <p className={'blog-writer-date'}>
                    {staffDate}
                </p>
                <p className={'latest-news'}>
                    Latest News
                </p>
            </CardHeader>
            <CardBody>
                <p className={'preview-text'}>
                    {previewText}
                </p>
                <div className={'image-wrapper'}>
                    <div className={'image-overlay d-flex'}>
                        <i className={'m-auto'}>
                            <FontAwesomeIcon icon="link"/>
                        </i>
                    </div>

                    {blogImage == '' || blogImage == undefined ? null :
                        (<img src={`https://nestidd-assets.s3.us-east-2.amazonaws.com/${blogImage}`} alt=""/>)
                    }

                </div>

            </CardBody>
            <CardFooter>
                <div className={'text-center mt-3 d-flex justify-content-around'}>

                    <NavLink to={`/admin/blogs/edit_blog/${id}`}>
                        <Button className={'edit-button'}>
                            Edit
                        </Button>
                    </NavLink>
                    {active == undefined || active == null || active == false ?
                        <SetBlogActiveModal active={active} id={id} buttonLabel={'Publish'}/>
                        : <SetBlogActiveModal active={active} id={id} buttonLabel={'Unpublish'}/>
                    }
                    <DeleteBlogModal id={id}/>
                </div>
            </CardFooter>

        </Card>


    );
};

export default AdminBlogCard;