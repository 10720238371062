import React, {useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import {useDispatch} from "react-redux";
import {loadUser} from "../actions/general/generalActions";


const AppRoute = ({component: Component, layout: Layout, isAuthProtected, ...rest}) => {


    return (
        <Route
            {...rest}
            render={props => {
                if(isAuthProtected && !localStorage.token) {
                    return (
                        <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                    )
                }

                return (
                    <Layout>
                        <Component {...props}/>
                    </Layout>
                )

            }}

        />
    );
};

export default AppRoute;