import React, {useEffect} from 'react';
import {Switch, BrowserRouter as Router} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {pubicRoutes, authProtectedRoutes} from "./routes";
import AppRoute from "./routes/AppRoute";
import NonAuthLayout from "./components/NonAuthLayout";
import Layout from "./components/Layout";
import './assets/styles/styles'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab, faLinkedin, faLinkedinIn, faInstagram, faTwitter, faFacebookF} from '@fortawesome/free-brands-svg-icons'
import {
    faCheckSquare,
    faCoffee,
    faLink,
    faSearch,
    faPlusCircle,
    faPhone,
    faUser,
    faUserFriends,
    faTimesCircle,
    faChevronRight,
    faEdit,
    faNewspaper,
    faImages, faQuoteLeft, faHamburger, faBars
} from '@fortawesome/free-solid-svg-icons'
import setAuthToken from "./utils/setAuthToken";
import {loadUser} from "./actions/general/generalActions";
import CacheBuster from "./components/CacheBuster";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

library.add(fab, faBars, faHamburger, faQuoteLeft, faImages, faNewspaper, faEdit, faCheckSquare, faCoffee, faLinkedin, faLinkedinIn, faChevronRight, faLink, faInstagram, faTwitter, faFacebookF, faSearch, faPlusCircle, faPhone, faUser, faUserFriends, faTimesCircle)

const trackingId = "UA-113389029-1";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    useEffect(() => {
        dispatch(loadUser())
    }, [])

    return (
        <Router history={history}>
            <Switch>
                {authProtectedRoutes.map((route, index) => (
                    <AppRoute
                        path={route.path}
                        layout={Layout}
                        component={route.component}
                        key={index}
                        isAuthProtected={true}
                    />
                ))}
                {pubicRoutes.map((route, index) => (
                    <AppRoute
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={index}
                        isAuthProtected={false}
                    />
                ))}


            </Switch>
        </Router>
    );
}

export default App;
