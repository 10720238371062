import React, {Fragment, useCallback, useState} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label,
    InputGroup
} from 'reactstrap';
import CustomInput from "reactstrap/es/CustomInput";
import {
    createNewCarousel,
    getSignedRequest,
    setTeamMemberImage,
    setTeamMemberImagePlaceholder, setToggle
} from "../../../actions/general/generalActions";
import {useDispatch, useSelector} from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../components/cropImage";
import axios from "axios";

const CreateNewCarousel = ({className}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('Choose File')
    const [uploadFile, setUploadFile] = useState({})
    const [imageName, setImageName] = useState('')
    const [imageSrc, setImageSrc] = useState('')
    const [altTag, setAltTag] = useState('')
    const [isFileUploaded, setIsFileUploaded] = useState(null)
    const [fileBlob, setFileBlob] = useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [test, setTest] = useState('')

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {

        try {

            const croppedImage = await getCroppedImg(
                fileBlob,
                croppedAreaPixels,
                rotation,
                file.type
            )
            console.log('done', { croppedImage })
            let newImage = croppedImage.split(',')
            await axios.post( 'https://nestidd-backend.herokuapp.com/api/upload_image',{'file': newImage[1], file_name: fileName, file_type: file.type})
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onFileChange = e => {
        setFile(e.target.files[0])
        setFileName(e.target.files[0].name)
        convertFileToBase64(e.target.files[0])
        setImageSrc(`https://nestidd-assets.s3.us-east-2.amazonaws.com/${e.target.files[0].name}`)
        setIsFileUploaded(true)
    }

    const convertFileToBase64 = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = function(e) {
            setFileBlob(reader.result)
        }
    }

    const onChange = e => {
        if(e.target.name === 'imageName') {
            setImageName(e.target.value)
        }
        if(e.target.name === 'altTag') {
            setAltTag(e.target.value)
        }
    }

    const onSubmit = async e => {
        e.preventDefault()

        const data = {
            image_name: imageName,
            image_src: imageSrc,
            alt_tag: altTag
        }

        showCroppedImage()
        dispatch(createNewCarousel(data)).then(()=>{
            dispatch(setToggle('toggle'))
        })
    }

    const renderCropping = () => {
        if(isFileUploaded) {
            return (
                <Fragment>
                    <div className="crop-container" style={{position: 'relative', minHeight: '250px'}}>
                        <Cropper
                            image={fileBlob}
                            crop={crop}
                            zoom={zoom}
                            aspect={16/7}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div>
                        <InputGroup>
                            <Label for="exampleRange">Range</Label>
                            <Input value={zoom} step={0.01} min={1} max={3} onChange={onChange} type="range" name="range" id="exampleRange" />
                        </InputGroup>
                    </div>
                </Fragment>
            )
        } else {
            return null
        }
    }

    return (
        <div className={'create-carousel-modal'}>
            <Button style={{background: '#003F67'}} onClick={toggle}>Create New</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <Form >
                    <ModalBody>
                        <CustomInput className={'mb-3'} onChange={onFileChange} name={'image'} type="file" id="exampleCustomFileBrowser"
                                     name="customFile"/>

                        <Input className={'mb-3'} onChange={onChange} name={'imageName'} placeholder={'Image Name'}/>
                        <Input className={'mb-3'} onChange={onChange} name={'altTag'} placeholder={'Alt Tag'}/>
                        {renderCropping()}

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onSubmit} style={{background: '#003F67'}}>Submit</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default CreateNewCarousel;