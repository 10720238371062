import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Container, Row, Col, Input, Button} from 'reactstrap'
import {getAllBlogs, getBlog, resetBlog} from "../../../actions/general/generalActions";
import BlogCard from "../../../components/cards/BlogCard";
import DeleteTeamMemberModal from "../../../components/DeleteTeamMemberModal";
import DeleteBlogModal from "../../../components/DeleteBlogModal";
import SetUserActiveModal from "../../../components/SetUserActiveModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdminBlogCard from "../../../components/cards/AdminBlogCard";

const BlogView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getAllBlogs())
        dispatch(resetBlog())
    }, [general.blogUpdateToggle, general.blogCount])

    const onChange = (e) => {
        if (e.target.name === 'search') {
            setSearch(e.target.value.toString().toLowerCase())
        }

    }



    return (
        <div className={'admin-blog-view'}>
            <Container>
                <Row>
                    <Col className={'mb-4'} md={12}>
                        <div className={'input-wrapper d-flex'}>
                            <div className={'ml-auto mb-auto mt-auto'}>
                                <Input onChange={onChange} name={'search'} placeholder={'Search...'}/>
                            </div>
                            <div className={'ml-auto'}>
                                <NavLink className={'create-new-blog-button'} to={'/admin/blogs/create_blog'}>
                                    <FontAwesomeIcon icon={'plus-circle'}/>
                                </NavLink>
                            </div>
                        </div>

                    </Col>
                    {general.allBlogs.map((blog, index) => {
                        const date = blog.blog_date.split(',')
                        return <Fragment>
                            <Col className={'mb-5'} md={4}>
                                <AdminBlogCard
                                    id={blog._id.$oid}
                                    blogTitle={blog.blog_title}
                                    staffDate={`${blog.blog_writer} / ${date[0]}, ${date[1]}`}
                                    previewText={blog.blog_preview_text}
                                    blogImage={blog.blog_preview_image}
                                    blogLink={`/admin/blogs/edit_blog/${blog._id.$oid}`}
                                    active={blog.active}
                                />

                            </Col>
                        </Fragment>
                    })}
                </Row>
            </Container>

        </div>
    );
};

export default BlogView;