import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Col, Container, Row} from "reactstrap";
import BlogCard from "../../../components/cards/BlogCard";
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";
import ContactFormSideview from "../../../components/ContactFormSideview";
import {getBlog, getTeamMembers} from "../../../actions/general/generalActions";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

const PublicBlogView = (props) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    useEffect(()=>{
        dispatch(getBlog(props.match.params.id))
    },[props.match.params.id])

    const stringToHTML = (str) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(str, 'text/html');
        return doc.body;
    };

    const createMarkup = () => { return {__html: 'First &middot; Second'}; };

    const blogStuff = () => {
        return <Fragment>
            <h1 className={' page-title'}>
                {general.currentBlog[0].blog_title}
            </h1>
            <img className={'mb-4'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
            <div className={'writer-date'}>
                {general.currentBlog[0].blog_writer} / {general.currentBlog[0].blog_date}
            </div>
            <div className={'latest-news mb-5'}>
                Latest News
            </div>
            <div className={'blog-content'}>
                {ReactHtmlParser(general.currentBlog[0].blog_content)}
            </div>
        </Fragment>

    }

    return (
        <div className={'blog'}>
            <Container fluid style={{width:' 90%'}}>
                <Row>
                    <Col md={8}>
                        {
                            general.currentBlog[0] == undefined ? null: blogStuff()
                        }
                    </Col>
                    <Col md={4}>
                        {/*<div style={{paddingLeft: '60px'}}>*/}
                        <div className={'sidemenucb-wrapper'}>
                            <LatestNewsBlogs/>
                            <ContactFormSideview/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PublicBlogView;