import React from 'react';
import {Card, CardBody} from 'reactstrap'
import ReactHtmlParser from "react-html-parser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Testimonial = ({quote, name, date}) => {
    return (
        <Card className={'testimonial-card d-flex'}>
            <div className={'testimonial-icon'}>
                <FontAwesomeIcon icon={'quote-left'}/>
            </div>
            <CardBody className={'m-auto w-100'}>

                    {ReactHtmlParser(quote)}

            </CardBody>
            {/*<div className={'testimonial-name'}>*/}
            {/*    {name} - {date}*/}
            {/*</div>*/}
        </Card>
    );
};

export default Testimonial;