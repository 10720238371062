import React, {Fragment, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import AdminSideNav from "./Navbars/Admin/AdminSideNav";
import AdminMainHeaderNav from "./Navbars/Admin/AdminMainHeaderNav";
import Breadcrumbs from "./breadcrumbs/Breadcrumbs";
import CacheBuster from "./CacheBuster";


const Layout = (props) => {
    const capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    }

    useEffect(()=> {
        let currentPage = capitalizeFirstLetter(props.location.pathname);
        document.title = `${currentPage} | Nestidd`;
    })

    console.log('auth')
    return (
        <Fragment>
            <CacheBuster/>
            <div id={'admin-wrapper'} className={'d-flex overflow-hidden'}>
                <AdminSideNav/>

                <div className={'main-content-wrapper'}>
                    <AdminMainHeaderNav/>
                    <Breadcrumbs urlPath={props.location.pathname}/>

                    <div className={'main-content'}>
                        {props.children}
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

export default (withRouter(Layout));