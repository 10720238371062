import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Col, Container, Row} from "reactstrap";
import ProfileCards from "../../../components/cards/ProfileCards";
import Testimonial from "../../../components/cards/Testimonial";
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";
import BlogCard from "../../../components/cards/BlogCard";
import ContactFormSideview from "../../../components/ContactFormSideview";
import {getAllActiveBlogs, getAllBlogs, getTeamMembers} from "../../../actions/general/generalActions";

const LatestNewsView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [leftColumnBlogs, setLeftColumnBlogs] = useState([])
    const [rightColumnBlogs, setRightColumnBlogs] = useState([])

    useEffect(() => {
        dispatch(getAllActiveBlogs())
    }, [])


    const blogs = (column) => {

        let blogColumn = []

        if(column === 'right') {
            general.allActiveBlogs.map((blog, index) =>{
                const date = blog.blog_date.split(',')
                if(index % 2 === 0){
                    blogColumn.push(<BlogCard
                        id={blog._id.$oid}
                        blogTitle={blog.blog_title}
                        staffDate={`${blog.blog_writer} / ${date[0]}, ${date[1]}`}
                        previewText={blog.blog_preview_text}
                        blogImage={blog.blog_preview_image}
                        blogLink={`/blog/${blog._id.$oid}`}
                    />)
                }
            })
            return blogColumn
        } else {
            general.allActiveBlogs.map((blog, index) =>{
                const date = blog.blog_date.split(',')
                if(index % 2 !== 0){
                    blogColumn.push(<BlogCard
                        id={blog._id.$oid}
                        blogTitle={blog.blog_title}
                        staffDate={`${blog.blog_writer} / ${date[0]}, ${date[1]}`}
                        previewText={blog.blog_preview_text}
                        blogImage={blog.blog_preview_image}
                        blogLink={`/blog/${blog._id.$oid}`}
                    />)
                }
            })
            return blogColumn
        }



    }


return (
    <div className={'latest-news-page'}>

        <Container fluid style={{width: ' 90%'}}>
            <Row>
                <Col md={8}>
                    <h1 className={'m-0 page-title'}>
                        Latest News
                    </h1>
                    <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                    <Row>
                        <Col md={6}>
                            {blogs('right')}

                        </Col>
                        <Col md={6}>
                            {blogs('left')}
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    {/*<div style={{paddingLeft: '60px'}}>*/}
                    <div className={'sidemenucb-wrapper'}>
                        <LatestNewsBlogs/>
                        <ContactFormSideview/>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);
}
;

export default LatestNewsView;