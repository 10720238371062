import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, Label } from 'reactstrap';
import axios from 'axios'
import {Editor} from "@tinymce/tinymce-react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createNewTestimonial, editTestimonial, getTestimonial, setToggle} from "../actions/general/generalActions";


const EditTestimonialModal = ({className, id, nameTestimonial, content}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [blogContent, setBlogContent] = useState('')
    const [name, setName] = useState('')
    const [hasDataBeenSet, setHasDataBeenSet] = useState(false)


    useEffect(()=>{
        setName(nameTestimonial)
        setBlogContent(content)
    }, [])


    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
        setBlogContent(content)
    }

    const onChange = (e) => {
        setName(e.target.value)
    }

    const create = () => {
        const data = {
            name: name,
            quote: blogContent
        }

        dispatch(editTestimonial(id, data)).then(()=>{
            dispatch(setToggle('t'))
        })
    }

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button style={{background: '#003F67'}} onClick={toggle}>
                Edit
            </Button>
            <Modal size={'lg'} isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <InputGroup className={'mb-3'}>
                        <Label className={'mr-3'}>
                            Name:
                        </Label>
                        <Input value={name} onChange={onChange}/>
                    </InputGroup>
                    <Editor
                        apiKey='ewwrgr426zy6sptaah1255v6yyqp01jgen6yi8umxvqqalkd'
                        initialValue={content}
                        init={{
                            height: 200,
                            menubar: false,
                            branding: false,
                            plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable',
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                            image_advtab: true,

                            /* we override default upload handler to simulate successful upload*/
                            images_upload_handler: function (blobInfo, success, failure) {

                            },

                        }}
                        onEditorChange={handleEditorChange}
                    />                </ModalBody>
                <ModalFooter>
                    <Button style={{background: '#003F67'}} onClick={create}>Save</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EditTestimonialModal;