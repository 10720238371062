import React, {Fragment, useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {deleteTeamMemeber, setToggle, updateCarousel, updateTeamOrder} from "../actions/general/generalActions";

const SetTeamOrderModal = ({buttonLabel, className, id, currentOrderNumber}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [modal, setModal] = useState(false);
    const [order, setOrder] = useState(currentOrderNumber)

    const toggle = () => setModal(!modal);

    const setActive = () => {
        const data = {
            sort_order: order
        }
        dispatch(updateTeamOrder(id, data)).then(()=>{
            dispatch(setToggle('toggle'))
        })
        setModal(!modal)
    }

    const options = () => {
        let optionsList = []
        for (let i = 0; i < general.teamMembers.length; i++) {
            optionsList.push(<option value={i+1}>{i + 1}</option>)
        }
        return optionsList
    }

    const onChange =  e => {
        setOrder(e.target.value)
        console.log(e.target.value)
    }

    return (
        <Fragment>
            <Input onClick={toggle} style={{width: '20%'}} value={currentOrderNumber}/>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <Input onChange={onChange} value={order} type="select"  id="exampleSelect">
                        {options()}
                    </Input>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={setActive}>Yes</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default SetTeamOrderModal;