import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {deleteTeamMemeber, deleteTestimonial, deleteUser, setToggle} from "../actions/general/generalActions";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DeleteUserModal = ({buttonLabel, className, uid}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [modal, setModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteUser(uid)).then(()=>{
            dispatch(setToggle(''))
        })
        setModal(!modal)
    }

    const toggle = () => setModal(!modal);
    return (
        <div>
            <Button className={'p-0 text-center'} style={{background: 'none', border: 'none'}} onClick={toggle}>

                    <i style={{color: 'red', fontSize: '25px', lineHeight: '0'}}>
                        <FontAwesomeIcon icon={'times-circle'}/>

                </i>

            </Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    Are you sure you want to delete?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onDelete}>Yes</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteUserModal;