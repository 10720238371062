import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CarouselImagePopup = ({className, image}) => {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);


    return (
        <div>
            <Button className={'p-0 border-none'} onClick={toggle}>
                <img style={{width: '100%'}} src={image} alt=""/>
            </Button>
            <Modal size={'lg'} isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <img style={{width: '100%'}} src={image} alt=""/>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default CarouselImagePopup;