import React from 'react';
import {Redirect} from 'react-router-dom'
import HomeView from "../Pages/NonAuthPages/Home/HomeView";
import WhoWeAreView from "../Pages/NonAuthPages/WhoWeAre/WhoWeAreView";
import LeadershipView from "../Pages/NonAuthPages/Leadership/LeadershipView";
import LatestNewsView from "../Pages/NonAuthPages/LatestNews/LatestNewsView";
import Dsp from "../Pages/NonAuthPages/DSP/Dsp";
import Postcard from "../Pages/NonAuthPages/DSP/Postcard";
import DashboardView from "../Pages/AuthPages/Dashboard/DashboardView";
import SignInView from "../Pages/NonAuthPages/SignIn/SignInView";
import TeamMembersView from "../Pages/AuthPages/TeamMembers/TeamMembersView";
import TeamMemberView from "../Pages/AuthPages/TeamMembers/TeamMemberView";
import BlogView from "../Pages/AuthPages/Blog/BlogView";
import CreateBlog from "../Pages/AuthPages/Blog/CreateBlog";
import PublicBlogView from "../Pages/NonAuthPages/LatestNews/PublicBlogView";
import FindAHome from "../Pages/NonAuthPages/FindAHome/FindAHome";
import ContactUs from "../Pages/NonAuthPages/ContactUs/ContactUs";
import CreateTeamMemberView from "../Pages/AuthPages/TeamMembers/CreateTeamMemberView";
import EditBlog from "../Pages/AuthPages/Blog/EditBlog";
import UserEditProfile from "../Pages/AuthPages/Account/UserEditProfile";
import UsersList from "../Pages/AuthPages/Account/UsersList";
import TestBlog from "../Pages/AuthPages/Blog/TestBlog";
import AllCarousels from "../Pages/AuthPages/Carousel/AllCarousels";
import ViewAllTestimonials from "../Pages/AuthPages/Testimonials/ViewAllTestimonials";

const authProtectedRoutes = [

    {
        path: "/admin/dashboard",  breadCrumbTitleList: ['Dashboard'], component: DashboardView,
    },
    {
        path: "/admin/team_members/create", breadCrumbTitleList: ['Create'], component: CreateTeamMemberView,
    },
    {
        path: "/admin/team_members/:id", breadCrumbTitleList: ['Edit'],component: TeamMemberView,
    },

    {
        path: "/admin/team_members", breadCrumbTitleList: ['Team'], component: TeamMembersView,
    },
    {
        path: "/admin/blogs/create_blog", breadCrumbTitleList: ['Team'], component: CreateBlog,
    },
    {
        path: "/admin/blogs/edit_blog/:id", breadCrumbTitleList: ['Team'], component: EditBlog,
    },
    {
        path: "/admin/blogs", breadCrumbTitleList: ['Team'], component: BlogView,
    },
    {
        path: "/admin/test_blog", breadCrumbTitleList: ['Team'], component: TestBlog,
    },
    {
        path: "/admin/users/:id", breadCrumbTitleList: ['Team'], component: UserEditProfile,
    },
    {
        path: "/admin/users", breadCrumbTitleList: ['Team'], component: UsersList,
    },
    {
        path: "/admin/sliders", breadCrumbTitleList: ['Sliders'], component: AllCarousels,
    },
    {
        path: "/admin/testimonials", breadCrumbTitleList: ['Sliders'], component: ViewAllTestimonials,
    },
    // // this route should be at the end of all other api
    { path: "/admin", exact: true, component: () => <Redirect to="/admin/dashboard" /> }
];

const pubicRoutes = [
    //{path: "/login", component: Login}
    {path: "/home", component: HomeView},
    {path: "/who_we_are", component: WhoWeAreView},
    {path: "/leadership", component: LeadershipView},
    {path: "/blogs", component: LatestNewsView},
    {path: "/blog/:id", component: PublicBlogView},
    {path: "/thank_a_dsp/postcard/:postcardID", component: Postcard},
    {path: "/thank_a_dsp", component: Dsp},
    {path: "/find_a_home", component: FindAHome},
    {path: "/contact_us", component: ContactUs},
    {path: "/login", component: SignInView},
    { path: "/", exact: true, component: () => <Redirect to="/home" /> }
]

export {authProtectedRoutes, pubicRoutes}