import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from 'react-router-dom'
import { Row, Col, CardBody, Card, Alert,Container, Form, FormGroup, Input, InputGroup, Label, Button } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import {loadUser, login} from "../../../actions/general/generalActions";
const SignInView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [resAlert, setResAlert] = useState(false)
    const [goodAlert, setGoodAlert] = useState(false)
    const [badAlert, setBadAlert] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            user: user,
            password: password
        }

        dispatch(login(data), loadUser()).then((res) => {

            setResAlert(true)
            setGoodAlert(true)

        }).catch((error) => {
            setResAlert(true)
        })

    }

    const redirect = () => {

    }
    if(general.isAuthenticated) {
        return <Redirect to={'/admin/dashboard'}/>
    }

    const onChange = (e) => {
        if(e.target.name === 'user') {
            setUser(e.target.value)
        }

        if(e.target.name === 'password') {
            setPassword(e.target.value)
        }
    }

    const addAlert = () => {
        if (resAlert && goodAlert) {
            return <Alert color="success">
                Logging In
            </Alert>
        } else if (resAlert) {
            return <Alert color="danger">
                Invalid Username/Email or Password
            </Alert>
        }
    }


    return (
        <div className={'login'}>
            <div className="home-btn d-none d-sm-block text-center">
                <img src={require('../../../assets/images/nestidd_small_logo.svg')} alt=""/>
            </div>

            <div className="account-pages pt-sm-5">
                <Container>
                    <Row className="justify-content-center">

                        <Col md={8} lg={6} xl={5}>
                            <Col md={12}>
                                {addAlert()}
                            </Col>
                            <Card className="overflow-hidden">
                                <CardBody>
                                    <Form onSubmit={onSubmit}>
                                        <FormGroup>
                                            <Label>
                                                Username or Email Address
                                            </Label>
                                            <Input onChange={onChange} name={'user'}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>
                                                Password
                                            </Label>
                                            <Input onChange={onChange} name={'password'}/>
                                        </FormGroup>
                                        <FormGroup className={'d-flex'}>
                                           <Button type={'submit'} className={'ml-auto'}>
                                               Log In
                                           </Button>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default SignInView;