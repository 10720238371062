import React, {Fragment} from 'react';

const RoundAvatar = ({userImage}) => {
    return (
        <Fragment>
            <img className={'profile-image'} src={require(`../../assets/images/${userImage}`)} alt=""/>
        </Fragment>
    );
};

export default RoundAvatar;