import React, {useState} from 'react';
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {contactUs} from "../../../actions/general/generalActions";

const bgImage = require('../../../assets/images/contact_us_bg.jpg')

const ContactUs = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [name, setName] = useState('')
    const [organization, setOrganization] = useState('')
    const [location, setLocation] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [resAlert, setResAlert] = useState(false)
    const [goodAlert, setGoodAlert] = useState(false)
    const [badAlert, setBadAlert] = useState(false)


    const onChange = (e) => {
        if (e.target.name == 'name') {
            setName(e.target.value)
        }
        if (e.target.name == 'organization') {
            setOrganization(e.target.value)
        }
        if (e.target.name == 'location') {
            setLocation(e.target.value)
        }
        if (e.target.name == 'email') {
            setEmail(e.target.value)
        }
        if (e.target.name == 'phone') {
            setPhone(e.target.value)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            name: name,
            organization: organization,
            location: location,
            email: email,
            phone: phone
        }

        dispatch(contactUs(data)).then(() => {
            setResAlert(true)
            setGoodAlert(true)

            setName('')

            setOrganization('')

            setLocation('')

            setEmail('')

            setPhone('')

        }).catch((error) => {
            setResAlert(true)
        })
    }

    const addAlert = () => {
        if (resAlert && goodAlert) {
            return <Alert color="success">
                Message Sent
            </Alert>
        } else if (resAlert) {
            return <Alert color="danger">
                Ooops! Something went wrong. Message was not sent!
            </Alert>
        }
    }

    return (
        <div className={'contact-us-page'}>
            <Container className={'mb-5'} fluid style={{width: ' 90%'}}>
                <h1 className={'m-0 page-title'}>
                    Contact Us
                </h1>
                <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
            </Container>
            <div className={'form-wrapper'} style={{backgroundImage: `url(${bgImage})`}}>
                <div className={'overlay'}>

                </div>
                <Container>
                    <h1 className={'text-center'}>
                        Interested in learning more?
                    </h1>
                    <h1 className={'text-center'}>
                        Contact us.
                    </h1>
                    <Form onSubmit={onSubmit} style={{width: '40%'}}>
                        {addAlert()}
                        <FormGroup>
                            <Input value={name} onChange={onChange} name={'name'} required placeholder={'Full Name'}/>
                        </FormGroup>
                        <FormGroup>
                            <Input value={organization} onChange={onChange} name={'organization'} required
                                   placeholder={'Name of Organization'}/>
                        </FormGroup>
                        <FormGroup>
                            <Input value={location} onChange={onChange} name={'location'} required
                                   placeholder={'Where are you located?'}/>
                        </FormGroup>
                        <FormGroup>
                            <Input value={email} type={'email'} onChange={onChange} name={'email'} required placeholder={'Email'}/>
                        </FormGroup>
                        <FormGroup>
                            <Input value={phone} onChange={onChange} name={'phone'} placeholder={'Phone Number Optional'}/>
                        </FormGroup>
                        <FormGroup className={'d-flex'}>
                            <Button className={'ml-auto'}>
                                Learn More
                            </Button>
                        </FormGroup>
                    </Form>
                </Container>
            </div>
        </div>
    );
};

export default ContactUs;