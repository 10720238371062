import {
    AUTH_ERROR,
    DELETE_TEAM_MEMBER,
    GET_ACTIVE_TEAM_MEMBERS,
    GET_ALL_ACTIVE_BLOGS,
    GET_ALL_ACTIVE_CAROUSELS,
    GET_ALL_BLOGS,
    GET_ALL_CAROUSELS, GET_ALL_TESTIMONIALS,
    GET_ALL_USERS,
    GET_BLOG,
    GET_FILE,
    GET_TEAM_MEMBER,
    GET_TEAM_MEMBERS, GET_TESTIMONIAL,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT, RESET_BLOG, RESET_TEAM_MEMBER,
    SET_BLOG_COUNT,
    SET_BLOG_UPDATE_TOGGLE,
    SET_TEAM_MEMBER_IMAGE,
    SET_TEAM_MEMBER_IMAGE_PLACEHOLDER, SET_UPDATE_TOGGLE,
    USER_LOADED
} from "./actionTypes";
import axios from 'axios'
import setAuthToken from "../../utils/setAuthToken";


export const loadUser = () => async dispatch => {
    if(localStorage.token) {
        setAuthToken(localStorage.token)
    }

    try {
        const res = await axios.get('https://nestidd-backend.herokuapp.com/api/auth')
        dispatch({
            type: USER_LOADED,
            payload: res.data.user
        })
    } catch (e) {
        dispatch({
            type: AUTH_ERROR
        })
    }

}

export const setTeamMemberImage = (image) => async dispatch => {
    dispatch({
        type: SET_TEAM_MEMBER_IMAGE,
        payload: image
    })
}

export const setTeamMemberImagePlaceholder = (image) => async dispatch => {
    dispatch({
        type: SET_TEAM_MEMBER_IMAGE_PLACEHOLDER,
        payload: image
    })
}


export const getTeamMembers = () => async dispatch => {

    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_team_members')

    dispatch({
        type: GET_TEAM_MEMBERS,
        payload: res.data
    })
}



export const getActiveTeamMembers = () => async dispatch => {

    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_active_team_members')

    dispatch({
        type: GET_ACTIVE_TEAM_MEMBERS,
        payload: res.data
    })
}



export const getTeamMember = (id) => async dispatch => {

    if(id <= 0) {
        dispatch({
            type: GET_TEAM_MEMBER,
            payload: {}
        })
    }

    const res = await axios.get(`https://nestidd-backend.herokuapp.com/api/get_team_member/${id}`)

    dispatch({
        type: GET_TEAM_MEMBER,
        payload: res.data
    })
}

export const resetTeamMember = () => async dispatch => {
    dispatch({
        type: RESET_TEAM_MEMBER,

    })
}


export const createTeamMember = teamMember => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/create_team_member', teamMember)
}

export const editTeamMember = (teamMember, id ) => async dispatch => {
    await axios.put(`https://nestidd-backend.herokuapp.com/api/update_team_member/${id}`, teamMember).then(res =>{
        console.log(res.data)
    })
}

export const deleteTeamMemeber = (id) => async dispatch => {
    await axios.delete(`https://nestidd-backend.herokuapp.com/api/delete_team_member/${id}`).then(res => {
        console.log(res.data)
    })

    dispatch({
        type: DELETE_TEAM_MEMBER
    })
}

export const getAllBlogs = () => async dispatch => {
    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_blogs')

    dispatch({
        type: GET_ALL_BLOGS,
        payload: res.data
    })
}

export const getAllActiveBlogs = () => async dispatch => {
    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_active_blogs')

    dispatch({
        type: GET_ALL_ACTIVE_BLOGS,
        payload: res.data
    })
}

export const getBlog = (id) => async dispatch => {

    const res = await axios.get(`https://nestidd-backend.herokuapp.com/api/get_blog/${id}`)

    dispatch({
        type: GET_BLOG,
        payload: res.data
    })
}

export const resetBlog = () => async dispatch => {
    dispatch({
        type: RESET_BLOG
    })
}

export const deleteBlog = (id) => async dispatch => {
    await axios.delete(`https://nestidd-backend.herokuapp.com/api/delete_blog/${id}`)

    dispatch({
        type: SET_BLOG_COUNT
    })
}

export const setBlogActive = (id, data) => async dispatch => {
    console.log(data)
    await axios.put(`https://nestidd-backend.herokuapp.com/api/set_blog_active/${id}`, data)
}


export const setTeamMemberActive = (id) => async dispatch => {
    await axios.get(`https://nestidd-backend.herokuapp.com/api/set_team_member_active_status/${id}`)
}


export const getSignedRequest = (file) => async dispatch => {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('file_type', file.type)

    try {
        const res = await axios.post('https://nestidd-backend.herokuapp.com/api/sign_s3', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        console.log(res.data)
        //await uploadFiles(file, res.data.data, res.data.url);
        // let postData = new FormData();
        //
        // for(const key in res.data.data.fields){
        //     postData.append(key, res.data.data.fields[key]);
        // }
        // postData.append('file', file);
        // await axios.post(res.data.data.url, postData)

        let xhr = new XMLHttpRequest();
        xhr.open("POST", res.data.data.url);

        let postData = new FormData();
        for(const key in res.data.data.fields){
            postData.append(key, res.data.data.fields[key]);
        }
        postData.append('file', file);

        xhr.send(postData)

        return {fileName: file.name}

    }
    catch (e) {
        if(e.response.status === 500) {
            console.log('There was a problem with the server')
        } else {
            console.log('test')
        }
    }

}

export const uploadFiles = (file, s3Data, url) => {
    let postData = new FormData();

    for(const key in s3Data.fields){
        postData.append(key, s3Data.fields[key]);
    }
    postData.append('file', file);

    axios.post(s3Data.url, postData)

}


export const setFile = file => async e => {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('file_type', file.type)
    try {
        const res = await axios.post('https://nestidd-backend.herokuapp.com/api/sign_s3', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return {data: res.data.url}

        // return new Promise((resolve, reject ) => {
        //     resolve({data: {link: res.data.url}})
        // })

    } catch (e) {
        if(e.response.status === 500) {
            console.log('There was a problem with the server')
        } else {
            console.log('test')
        }
    }
}

export const login = (creds) => async dispatch => {


        await axios.post('https://nestidd-backend.herokuapp.com/api/login_user', creds).then((res)=>{

            if(!res.data.success){
                dispatch({
                    type: LOGIN_FAIL
                })
            } else {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                })
            }

        })
}

export const logout = () => async dispatch => {
    dispatch({
        type: LOGOUT
    })
}

export const checkForFile = (data) => async dispatch => {
    const res = await axios.post(`https://nestidd-backend.herokuapp.com/api/check_file_upload`, data)
    console.log(res)
    return res
}

export const createNewUser = data => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/create_user', data)
}

export const getAllUsers = () => async dispatch => {
    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_users')

    dispatch({
        type: GET_ALL_USERS,
        payload: res.data
    })
}

export const getAllCarousels = () => async dispatch => {
    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_carousels')

    dispatch({
        type: GET_ALL_CAROUSELS,
        payload: res.data
    })
}

export const getAllActiveCarousels = () => async dispatch => {
    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_active_carousels')

    dispatch({
        type: GET_ALL_ACTIVE_CAROUSELS,
        payload: res.data
    })
}

export const setCarouselActive = (id) => async dispatch => {
    await axios.get(`https://nestidd-backend.herokuapp.com/api/update_carousel_active/${id}`)

}

export const createNewCarousel = (data) => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/create_new_carousel', data)
}

export const deleteCarousel = (id) => async dispatch => {
    await axios.delete(`https://nestidd-backend.herokuapp.com/api/delete_carousel/${id}`)
}

export const updateCarousel = (id, data) => async dispatch => {
    await axios.put(`https://nestidd-backend.herokuapp.com/api/update_carousel_sort/${id}`, data)
}

export const updateTeamOrder = (id, data) => async dispatch =>{
    await axios.put(`https://nestidd-backend.herokuapp.com/api/update_team_member_order/${id}`, data)

}

export const setToggle = (toggle) => async dispatch => {
    console.log('toggle')
    dispatch({
        type: SET_UPDATE_TOGGLE,
        payload: toggle
    })
}

export const getAllTestimonials = () => async dispatch => {
    const res = await axios.get('https://nestidd-backend.herokuapp.com/api/get_all_testimonials')

    dispatch({
        type: GET_ALL_TESTIMONIALS,
        payload: res.data
    })
}


export const getTestimonial = (id) => async dispatch => {
    const res = await axios.get(`https://nestidd-backend.herokuapp.com/api/get_testimonial/${id}`)

    dispatch({
        type: GET_TESTIMONIAL,
        payload: res.data
    })
}

export const createNewTestimonial = data => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/create_testimonial', data)
}

export const editTestimonial = (id, data) => async dispatch => {
    await axios.put(`https://nestidd-backend.herokuapp.com/api/edit_testimonial/${id}`, data)
}

export const deleteTestimonial = (id) => async dispatch => {
    await axios.delete(`https://nestidd-backend.herokuapp.com/api/delete_testimonial/${id}`)
}

export const deleteUser = (id) => async dispatch => {
    await axios.delete(`https://nestidd-backend.herokuapp.com/api/delete_user/${id}`)
}

export const editUser = (id, data) => async dispatch => {
    await axios.put(`https://nestidd-backend.herokuapp.com/api/edit_user/${id}`, data)
}


export const contactUs = (data) => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/contact_us', data)
}

export const contactUsSide = (data) => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/contact_us_side', data)
}

export const thankADsp = (data) => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/thank_a_dsp', data)
}

export const findAHome= (data) => async dispatch => {
    await axios.post('https://nestidd-backend.herokuapp.com/api/find_a_home', data)
}