import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deleteTeamMemeber, deleteBlog, deleteCarousel, setToggle} from "../actions/general/generalActions";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DeleteCarouselModel = ({buttonLabel, className, id}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [modal, setModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteCarousel(id)).then(()=>{
            dispatch(setToggle('toggle'))
        })

        setModal(!modal)
    }
    const toggle = () => setModal(!modal);
    return (
        <div>
            <Button color="danger" onClick={toggle}>
                <FontAwesomeIcon icon={'times-circle'}/>
            </Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onDelete}>Yes</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteCarouselModel;