import React from 'react';
import {Card, CardHeader, CardBody, CardFooter, Button, Container, Row, Col} from 'reactstrap'
import HomeCarouselSection from "./HomeCarouselSection";
import HomeNavCards from "../../../components/cards/HomeNavCards";
import SimpleLeasingCardSection from "./SimpleLeasingCardSection";
import CommunityHomeMaintenanceSection from "./CommunityHomeMaintenanceSection";
import SalesLeasebackSection from "./SalesLeasebackSection";

const archImg = require('../../../assets/images/landing_page_arch.svg')

const HomeView = () => {
    return (
        <div className={'home'}>
            <section  className={'home-starts-here d-flex flex-column'} style={{backgroundImage: `url(${archImg})`}}>
                <img className={'mt-auto'} src={require('../../../assets/images/nestidd_landing_logo.png')} alt=""/>
                <h1 className={'landing-header'}>
                    Home starts here.
                </h1>
                <p className={'landing-paragraph'}>
                    We buy houses, customize them for people with disabilities, lease and maintain them - affordably.
                </p>
            </section>
            <section  className={'home-nav-card-section'}>
                <Container  fluid style={{width: '95%'}}>
                    <Row>
                        <Col className={'mb-4'} md={4}>
                            <HomeNavCards to={'simple-leasing'} headerTitle={'Simple Leasing'} bodyText={'We find new homes and commercial properties for growing care provider organizations. Provide us your criteria and we will send you options within two days.'}/>
                        </Col>
                        <Col className={'mb-4'} md={4}>
                            <HomeNavCards to={'community-home-maintenance'} headerTitle={'Maintenance'} bodyText={'Nestidd360° maintains community homes all across the country. '}/>
                        </Col>
                        <Col className={'mb-4'} md={4}>
                            <HomeNavCards to={'sales-leaseback'} headerTitle={'Sale/Leaseback'} bodyText={'Cash: unlocked. Operations: unchanged. We will buy your community homes and lease them back to you for as long as you’d like.'}/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={'home-carousel-section position-relative'}>
                <HomeCarouselSection/>
            </section>
            <SimpleLeasingCardSection/>
            <CommunityHomeMaintenanceSection/>
            <SalesLeasebackSection/>
        </div>
    );
};

export default HomeView;