import React, {Fragment} from 'react';
import {Nav, NavItem, Container, Row, Col, Button, Label, Input, InputGroup} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {NavLink} from "react-router-dom";
import {Link, animateScroll as scroll} from 'react-scroll'

const MainFooter = (path) => {

    const leftNavGroup = () => {
        if(path.path !== '/home') {
            return <Fragment>
                <Nav className={'footer-nav-group left-footer-nav-group'}>
                    <NavItem>
                        <NavLink to={'/'}>
                            What We Do
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to={'/who_we_are'}>
                            About
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <a  href={'home#simple-leasing'}>
                            Simple Leasing
                        </a>
                    </NavItem>
                    <NavItem>
                        <a  href={'home#community-home-maintenance'}>

                        Community Home Maintenance
                        </a>
                    </NavItem>
                    <NavItem>
                        <a  href={'home#sales-leaseback'}>
                            Sale / Leaseback
                        </a>

                    </NavItem>
                    <NavItem>
                        <NavLink to={'/contact_us'}>
                            Contact Us
                        </NavLink>
                    </NavItem>

                </Nav>
            </Fragment>
        } else {
            return <Fragment>
                <Nav className={'footer-nav-group left-footer-nav-group'}>
                    <NavItem>
                        <NavLink to={'/'}>
                            What We Do
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to={'/who_we_are'}>
                            About
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <Link to="simple-leasing" spy={true} smooth={true} offset={50} duration={500}>
                            Simple Leasing
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to={'community-home-maintenance'} spy={true} smooth={true} offset={0} duration={500}>

                            Community Home Maintenance
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to={'sales-leaseback'} spy={true} smooth={true} offset={50} duration={500}>
                            Sale / Leaseback
                        </Link>
                    </NavItem>
                    <NavItem>
                        <NavLink to={'/contact_us'}>
                            Contact Us
                        </NavLink>
                    </NavItem>

                </Nav>
            </Fragment>
        }
    }

    return (
        <footer className={'main-footer'}>
            <Container className={'d-flex'}>
                {leftNavGroup()}
                <Nav className={'footer-nav-group right-footer-nav-group'}>
                    <NavItem className={'mb-3'}>
                        <NavLink className={'btn'} to={'/find_a_home'}>
                            Find a Home
                        </NavLink>
                    </NavItem>
                    <NavItem className={'mb-3'}>
                        <a className={'btn'} target={'_blank'}
                           href="http://www.nestidd360.com/">
                            Nestidd360°
                        </a>
                    </NavItem>
                    <NavItem className={'mb-3'}>


                        <NavLink className={'btn'} to={'/thank_a_dsp'}>
                            Thank a DSP
                        </NavLink>


                    </NavItem>
                </Nav>
                <div className={'footer-contact-us'}>
                    <h3>
                        Contact Us
                    </h3>
                    <div className={'d-flex'}>

                            <InputGroup className={'flex-column mb-2'}>
                                <Label><span className={'asterisk'}>*</span>Full Name</Label>
                                <Input className={'w-100'}/>
                            </InputGroup>
                            <InputGroup className={'flex-row'}>
                                <div>
                                    <Label><span className={'asterisk'}>*</span>Email</Label>
                                    <Input className={'w-100'}/>
                                </div>

                            </InputGroup>

                        <Button className={'mt-auto ml-3'}>Submit</Button>
                    </div>
                </div>
                <div className={'image-group ml-auto'}>
                    <div className={'mb-3 nestidd-logo-wrapper'}>
                        <img className={'nestidd-logo'} src={require('../../assets/images/nestidd_footer_logo.png')}
                             alt=""/>
                    </div>
                    <div className={'d-flex social-media-group'}>
                        <a target={'_blank'} href="https://www.instagram.com/nestidd/">
                            <i>
                                <FontAwesomeIcon icon={['fab', 'instagram']}/>
                            </i>
                        </a>
                        <a target={'_blank'} href="https://www.facebook.com/Nestidd/">
                            <i>
                                <FontAwesomeIcon icon={['fab', 'facebook-f']}/>
                            </i>
                        </a>

                        <a target={'_blank'} href="https://twitter.com/_nestidd">
                            <i>
                                <FontAwesomeIcon icon={['fab', 'twitter']}/>
                            </i>
                        </a>
                    </div>
                </div>
            </Container>
            <Container className={'d-flex mt-3 copyright'}>
                <div className={'ml-auto copyright-text'}>
                    ©2020 Nestidd. All rights reserved.
                </div>
            </Container>
        </footer>
    );
};

export default MainFooter;