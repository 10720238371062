import React, {useState, useEffect, Fragment} from 'react';
import {useSelector, useDispatch} from "react-redux";
import packageJson from '../../package.json';

global.appVersion = packageJson.version;
const CacheBuster = (props) => {
    const [isLoading, setLoading] = useState(false);
    //const [isLatestVersion, setLatestVersion] = useState(false)
    const dispatch = useDispatch()
    const ip = useSelector(state => state.ipAddress)
    useEffect(() => {
    }, [])
    const refreshCacheAndReload = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    }
    if(!isLoading) {
        setLoading(true)
        return (
            refreshCacheAndReload
        )
    }
    return (
        <Fragment>
            {
                // !setLatestVersion ? null :
                //     refreshCacheAndReload()
            }
        </Fragment>
    );
};
export default CacheBuster;