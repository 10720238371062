import React, {Fragment} from 'react';
import AdminSideNavContent from "./AdminSideNavContent";

const AdminSideNav = () => {
    return (
        <Fragment>
            <AdminSideNavContent/>
        </Fragment>
    );
};

export default AdminSideNav;