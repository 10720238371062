import React from 'react';
import {Card, CardBody} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProfileCards = ({name, position, link, image, isPlaceholder, height}) => {

    //https://nestidd-assets.s3.us-east-2.amazonaws.com/
    return (
        <Card className={'profile-card'} style={{height: height}}>
            <CardBody className={'d-flex flex-column'}>
                <div className={'ml-auto linkedIn-icon'}><a target={'_blank'} href={link}><i> <FontAwesomeIcon icon={['fab', 'linkedin-in']} /></i></a></div>
                <div className={'text-center profile-image'}>
                    {
                        image == '' || image == undefined ? (
                            <img className={'mb-3'} src={require(`../../assets/images/blank_avatar.svg`)} alt=""/>
                        )
                            :(
                            <img className={'mb-3'} src={image} alt=""/>
                        )
                    }
                </div>
                <h1 className={'profile-name text-center'}>{name}</h1>
                <h2 className={'profile-position text-center mb-3'}>{position}</h2>
                <img className={'half-circle'} src={require('../../assets/images/orange_half_circle.svg')} alt=""/>
            </CardBody>
        </Card>
    );
};

export default ProfileCards;