import React, {useState} from 'react';
import {Col, Container, Row, Form, FormGroup, Input, Label, Button, InputGroup, Alert} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {contactUs, findAHome} from "../../../actions/general/generalActions";

const bgImage = require('../../../assets/images/find_a_home_bg.jpg')

const FindAHome = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [rooms, setRooms] = useState('')
    const [baths, setBaths] = useState('')
    const [homeType, setHomeType] = useState('')
    const [criteria, setCriteria] = useState('')
    const [date, setDate] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [organization, setOrganization] = useState('')
    const [resAlert, setResAlert] = useState(false)
    const [goodAlert, setGoodAlert] = useState(false)
    const [badAlert, setBadAlert] = useState(false)


    const onChange = (e) => {
        if (e.target.name == 'name') {
            setName(e.target.value)
        }
        if (e.target.name == 'organization') {
            setOrganization(e.target.value)
        }
        if (e.target.name == 'city') {
            setCity(e.target.value)
        }
        if (e.target.name == 'state') {
            setState(e.target.value)
        }
        if (e.target.name == 'zip') {
            setZip(e.target.value)
        }
        if (e.target.name == 'rooms') {
            setRooms(e.target.value)
        }
        if (e.target.name == 'baths') {
            setBaths(e.target.value)
        }
        if (e.target.name == 'homeType') {
            setHomeType(e.target.value)
        }
        if (e.target.name == 'criteria') {
            setCriteria(e.target.value)
        }
        if (e.target.name == 'date') {
            setDate(e.target.value)
        }
        if (e.target.name == 'email') {
            setEmail(e.target.value)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            city,
            state,
            zip,
            rooms,
            baths,
            homeType,
            criteria,
            name,
            email,
            organization
        }

        dispatch(findAHome(data)).then(() => {
            setResAlert(true)
            setGoodAlert(true)


            setName('')


            setOrganization('')

            setCity('')


            setState('')


            setZip('')


            setRooms('')


            setBaths('')


            setHomeType('')


            setCriteria('')


            setEmail('')


        }).catch((error) => {
            setResAlert(true)
        })
    }

    const addAlert = () => {
        if (resAlert && goodAlert) {
            return <Alert color="success">
                Message Sent
            </Alert>
        } else if (resAlert) {
            return <Alert color="danger">
                Ooops! Something went wrong. Message was not sent!
            </Alert>
        }
    }


    return (
        <div className={'find-a-home-page'}>
            <Container fluid style={{width: ' 90%'}}>
                <h1 className={'m-0 page-title'}>
                    Find A Home
                </h1>
                <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>


            </Container>
            <div className={'form-wrapper'} style={{backgroundImage: `url(${bgImage})`}}>
                <div className={'overlay'}>

                </div>
                <Container>
                    <h1 className={'text-center'}>
                        Do you need a community home?
                    </h1>
                    <h1 className={'text-center'}>
                        We will get you options within 24 hours
                    </h1>
                    <p className={'text-center'}>
                        Provide us with your housing criteria and our national home sourcing team will send you housing
                        options within 24 hours.
                    </p>

                    <Form onSubmit={onSubmit}>
                        <FormGroup>
                            <InputGroup className={'d-flex'}>
                                <div className={'w-50 mb-3'}>
                                    <Label>Where do you need a home?</Label>
                                    <Input value={city} required onChange={onChange} name={'city'} placeholder={'City, County'}/>
                                </div>
                                <Input value={state} required onChange={onChange} name={'state'} className={'mt-auto mb-3 ml-3'}
                                       placeholder={'State'}/>
                            </InputGroup>
                            <Input value={zip} onChange={onChange} name={'zip'} placeholder={'Zip Code(s)(Optional)'}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>What is your home criteria?</Label>
                            <Input required onChange={onChange} name={'rooms'} placeholder={'Number of Bedrooms'}
                                   type="select" id="exampleSelect">
                                <option>Number of Bedrooms</option>
                                <option value={'1 Bedroom'}>1 Bedroom</option>
                                <option value={'2 Bedroom'}>2 Bedroom</option>
                                <option value={'3 Bedroom'}>3 Bedroom</option>
                                <option value={'4 Bedroom'}>4 Bedroom</option>
                                <option value={'5 Bedroom'}>5 Bedroom</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Input required onChange={onChange} name={'baths'} placeholder={'Number of Bathrooms'}
                                   type="select" id="exampleSelect">
                                <option>Number of Bathrooms</option>
                                <option>1 Bathrooms</option>
                                <option>1.5 Bathrooms</option>
                                <option>2 Bathrooms</option>
                                <option>2.5 Bathrooms</option>
                                <option>3 Bathrooms</option>
                                <option>3.5 Bathrooms</option>
                                <option>4 Bathrooms</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Input required onChange={onChange} name={'homeType'}
                                   placeholder={'Is a single level home required?'} type="select" id="exampleSelect">
                                <option>Is a single level home required?</option>
                                <option>Yes, single level is required</option>
                                <option>No, single level is not required</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Input value={criteria} required onChange={onChange} name={'criteria'} type={'textarea'}
                                   placeholder={'Any additional criteria? (i.e. parking, exterior ramp, accessible shower, fence)'}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>What is your contact information (we will never share your email or send spam)
                                *</Label>
                            <Input value={name} required onChange={onChange} name={'name'} placeholder={'Full Name'}/>
                        </FormGroup>
                        <FormGroup>
                            <Input value={email} required type={'email'} onChange={onChange} name={'email'} placeholder={'Email'}/>
                        </FormGroup>
                        <FormGroup>
                            <Input value={organization} required onChange={onChange} name={'organization'}
                                   placeholder={'Name of Organization?'}/>
                        </FormGroup>
                        {addAlert()}
                        <FormGroup className={'d-flex'}>
                            <Button className={'ml-auto'}>
                                Send Me Home Options
                            </Button>
                        </FormGroup>
                    </Form>
                </Container>
            </div>
        </div>
    );
};

export default FindAHome;