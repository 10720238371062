import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {loadUser} from "../../../actions/general/generalActions";
import {useDispatch, useSelector} from "react-redux";
import DashboardCards from "../../../components/cards/DashboardCards";
import {Container, Row, Col} from 'reactstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DashboardView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    useEffect(()=> {
        dispatch(loadUser())
    }, [])
    return (
        <div>
            <Container>
                <Row>
                    <Col className={'mb-3'} md={3}>
                        <NavLink to={'/admin/team_members'}>
                            <DashboardCards color={'#003F67'} title={'Team Members'} icon={<FontAwesomeIcon icon={'user-friends'}/>} />
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={3}>
                        <NavLink to={'/admin/blogs'}>
                            <DashboardCards color={'#003F67'} title={'Blogs'} icon={<FontAwesomeIcon icon={'newspaper'}/>} />
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={3}>
                        <NavLink to={'/admin/sliders'}>
                            <DashboardCards color={'#003F67'} title={'Sliders'} icon={<FontAwesomeIcon icon={'images'}/>} />
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={3}>
                        <NavLink to={'/admin/testimonials'}>
                            <DashboardCards color={'#003F67'} title={'Testimonials'} icon={<FontAwesomeIcon icon={'quote-left'}/>} />
                        </NavLink>
                    </Col>
                    <Col className={'mb-3'} md={3}>
                        <NavLink to={'/admin/users'}>
                            <DashboardCards color={'#003F67'} title={'Users'} icon={<FontAwesomeIcon icon={'user-friends'}/>} />
                        </NavLink>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default DashboardView;