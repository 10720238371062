import React, {Fragment, useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container} from 'reactstrap';
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";
import ContactFormSideview from "../../../components/ContactFormSideview";
import ReactHtmlParser from "react-html-parser";

const BlogPreview = ({blogTitle, blogWriter, blogDate, blogContent, className}) => {


    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button style={{background: '#003F67'}} onClick={toggle}>Preview Blog</Button>
            <Modal  style={{maxWidth: '95%'}} centered size={'lg'} isOpen={modal} toggle={toggle} className={`${className} blog-preview`}>
                {/*<ModalHeader toggle={toggle}>Modal title</ModalHeader>*/}
                <ModalBody >
                    <Container fluid style={{width:' 90%'}}>
                        <Row>
                            <Col md={12}>
                                <div className={'blog'}>
                                    <h1 className={' page-title'}>
                                        {blogTitle}
                                    </h1>
                                    <img className={'mb-4'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                                    <div className={'writer-date'}>
                                        {blogWriter} / {blogDate}
                                    </div>
                                    <div className={'latest-news mb-5'}>
                                        Latest News
                                    </div>
                                    <div className={'blog-content'}>
                                        {ReactHtmlParser(blogContent)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {/*<Button color="primary" onClick={toggle}>Do Something</Button>{' '}*/}
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default BlogPreview;