import React, {useEffect} from 'react';
import {Container, Row, Col} from 'reactstrap';
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";
import ContactFormSideview from "../../../components/ContactFormSideview";
import {useDispatch, useSelector} from "react-redux";
import {getAllTestimonials, getTeamMembers} from "../../../actions/general/generalActions";
import Testimonial from "../../../components/cards/Testimonial";

const WhoWeAreView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    useEffect(()=>{
        dispatch(getAllTestimonials())
    }, [])

    return (
        <div className={'who-we-are'}>
            <Container fluid style={{width:' 90%'}}>
                <Row>
                    <Col md={8}>
                        <h1 className={'m-0 page-title'}>
                            Who we are
                        </h1>
                        <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                        <p>
                            Finding a good home in the community is essential for people with I/DD. But we know it’s one of the hardest parts of your job as a care provider.
                        </p>
                        <p>
                            We’ve got a solution – we find and buy homes in safe neighborhoods, renovate and customize them to your specifications so they’re accessible, comfortable and move-in ready. In the process, we handle the neighbors and zoning issues and you take advantage of our affordable leasing options, which include best-in-class property management.
                        </p>
                        <p>
                            Nestidd’s Simple Leasing, Sale/Leaseback, and Nestidd360° approach means you can spend less time dealing with real estate and more time caring for those who count on you. You can count on us too, because we believe everyone deserves a home where they can thrive, no matter what their abilities.
                        </p>
                        <Row className={'mt-5'}>
                            {
                                general.allTestimonials.map((item, index)=>(
                                    <Col className={'mb-5'} md={12}>
                                        <Testimonial date={item.date} name={item.name} quote={item.quote}/>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col md={4}>
                      {/*<div style={{paddingLeft: '60px'}}>*/}
                      <div className={'sidemenucb-wrapper'}>
                          <LatestNewsBlogs/>
                          <ContactFormSideview/>
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WhoWeAreView;