import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap'
import ChmCard from "../../../components/cards/ChmCard";
import {NavLink} from "react-router-dom";
const bgImage = require('../../../assets/images/chm_bg.svg')

const CommunityHomeMaintenanceSection = () => {

    return (
        <section id={'community-home-maintenance'} className={'community-home-section'}>
            <div className={'text-center mb-5'}>
                <h1 className={'cmh-title'}>
                    Community Home Maintenance
                </h1>
                <img src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
            </div>
            <div className={'chm-card-wrapper'}>
                <div className={'bg-image w-100'} style={{backgroundImage: `url(${bgImage})`}}>
                    <Container className='cm-container'>
                        <Row>
                            <Col md={12}>
                                <div className={'nestidd-360 text-center mt-4'}>
                                    <img src={require('../../../assets/images/nestidd_360.png')} alt=""/>
                                </div>
                            </Col>
                            <Col className={'mb-4'} md={3}>
                                <ChmCard cardImage={require('../../../assets/images/phone_24_7_img.svg')} cardText={'Submit work order requests 24/7, via the Nestidd360° portal or by phone.'}/>
                            </Col>
                            <Col className={'mb-4'} md={3}>
                                <ChmCard cardImage={require('../../../assets/images/us_map_img.svg')} cardText={'All vendors are licensed, insured, background checked, and trained for performing work at I/DD community homes'}/>
                            </Col>
                            <Col className={'mb-4'} md={3}>
                                <ChmCard cardImage={require('../../../assets/images/internal_tech_img.svg')} cardText={'Send work to \n' +
                                'your own internal technicians or employees through the Nestidd360° portal'}/>
                            </Col>
                            <Col className={'mb-4'} md={3}>
                                <ChmCard cardImage={require('../../../assets/images/online_portal_img.svg')} cardText={'Our online portal \n' +
                                'allows real-time analytics. Access before and after photos, vendor response times, and financial reporting. '}/>
                            </Col>
                            <Col md={12} className={'text-center mb-5'}>
                                <NavLink to={'/contact_us'}>
                                    <Button className={'request-demo-button'}>
                                        Request a Demo
                                    </Button>
                                </NavLink>

                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </section>
    );
};

export default CommunityHomeMaintenanceSection;