import React, {useRef} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import {Link, animateScroll as scroll} from 'react-scroll'


const HomeNavCards = ({headerTitle, bodyText, link, to}) => {

    return (
        <Card className={'home-nav-card text-center'}>
            <CardHeader >
                {headerTitle}
            </CardHeader>
            <CardBody>
                {bodyText}
            </CardBody>
            <CardFooter>
                <Link to={to} spy={true} smooth={true} offset={50} duration={500}>
                    <Button>
                        Learn More
                    </Button>
                </Link>

            </CardFooter>
        </Card>
    );
};

export default HomeNavCards;