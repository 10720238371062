import React, {useState, useCallback, Fragment} from 'react';
import {
    checkForFile,
    getSignedRequest,
    setTeamMemberImage,
    setTeamMemberImagePlaceholder, setToggle
} from "../actions/general/generalActions";
import {
    Button,
    CustomInput,
    Form,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
    Label,
    Alert, Col
} from "reactstrap";
import Cropper from 'react-easy-crop'
import getCroppedImg from "./cropImage";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios'
const dogImg = 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'





const ImagerCropper = ({className}) => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)
    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('Choose File')
    const [uploadFile, setUploadFile] = useState(null)
    const [isFileUploaded, setIsFileUploaded] = useState(null)
    const [fileBlob, setFileBlob] = useState('')
    const [modal, setModal] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [hasCreated, setHasCreated] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    const convertFileToBase64 = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = function(e) {
            setFileBlob(reader.result)
        }
    }


    const showCroppedImage = useCallback(async () => {
        try {

            const croppedImage = await getCroppedImg(
                fileBlob,
                croppedAreaPixels,
                rotation,
                file.type
            )
            console.log('done', { croppedImage })
            let newImage = croppedImage.split(',')

            dispatch(setTeamMemberImagePlaceholder(croppedImage))
            await axios.post( 'https://nestidd-backend.herokuapp.com/api/upload_image',{'file': newImage[1], file_name: fileName, file_type: file.type}).then((res)=>{
                dispatch(setTeamMemberImage(res.data.file_name))
                dispatch(setToggle('s'))
                setHasCreated(true)
                setTimeout(()=> {
                    setModal(false)

                }, 1000)
            })

        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onFileChange = e => {

        setFile(e.target.files[0])
        setFileName(e.target.files[0].name)
        console.log(fileName)
        convertFileToBase64(e.target.files[0])
        dispatch(getSignedRequest(e.target.files[0])).then((res)=>{
            const data = {
                file_name: res.fileName
            }


            dispatch(checkForFile(data)).then((res) => {
                setIsFileUploaded(res.data.status)
            })

        })
        setIsFileUploaded(true)
    }


    const onFileUploadChange = e => {
        console.log(e.target.files[0])
    }

    const onChange = e => {

        if(e.target.name === 'range') {
            setZoom(e.target.value)
        }
    }

    const onSubmit = async e => {
        e.preventDefault()
        dispatch(getSignedRequest(file))
    }

    const addAlert = () => {
        if(hasCreated){
            return <Alert color="success">
                Image has been uploaded!
            </Alert>
        }
    }

    const renderCropping = () => {
        if(isFileUploaded) {
            return (
                <Fragment>

                    <div className="crop-container" style={{position: 'relative', minHeight: '250px'}}>
                        <Cropper
                            image={fileBlob}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div>
                        <InputGroup>
                            <Label for="exampleRange">Range</Label>
                            <Input value={zoom} step={0.01} min={1} max={3} onChange={onChange} type="range" name="range" id="exampleRange" />
                        </InputGroup>
                    </div>
                </Fragment>
            )
        } else {
            return null
        }
    }


    const toggle = () => setModal(!modal);
    return (
        <div>

            <Button style={{background: '#003F67'}} onClick={toggle}>Choose an Image</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>

                <ModalHeader toggle={toggle}>Upload Profile Image</ModalHeader>
                <Form>
                    <ModalBody>
                        <div>
                            {addAlert()}
                        </div>
                        <InputGroup>
                            <CustomInput name={'image'} onChange={onFileChange} type="file" id="exampleCustomFileBrowser" name="customFile" />
                        </InputGroup>
                        {renderCropping()}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={showCroppedImage} color="primary">Save</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default ImagerCropper;