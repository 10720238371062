import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Form, FormGroup, Input, Label, InputGroup, Button} from 'reactstrap'
import CarouselImagePopup from "../../../components/CarouselImagePopup";
import CreateNewCarousel from "./CreateNewCarousel";
import {useDispatch, useSelector} from "react-redux";
import {getAllCarousels} from "../../../actions/general/generalActions";
import DeleteCarouselModel from "../../../components/DeleteCarouselModal";
import SetCarouselActiveModal from "../../../components/SetCarouselActiveModal";
import SetUserActiveModal from "../../../components/SetUserActiveModal";
import SetCarouselOrderModal from "../../../components/SetCarouselOrderModal";


const AllCarousels = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)



    useEffect(()=> {
        dispatch(getAllCarousels())

    }, [general.updateToggle])


    return (
        <div className={'pt-3'}>
            <Container>
                <Row>
                    <Col className={'text-center mb-4'} md={12}>
                        <CreateNewCarousel/>
                    </Col>

                    {general.allCarousels.map((item, index) => (
                        <Col className={'mb-3'} md={4}>
                            <Card className={'admin-carousel-card'}>
                                <CardHeader>
                                    <div className={'d-flex justify-content-center'}>
                                        <Label>
                                            Order:
                                        </Label>
                                        <SetCarouselOrderModal id={item._id.$oid} currentOrderNumber={item.sort_order}/>
                                    </div>
                                </CardHeader>
                                <CardBody className={'p-0'}>
                                    <CarouselImagePopup image={item.image_src}/>
                                </CardBody>
                                <CardFooter >
                                    <div className={'d-flex justify-content-around mt-3'}>
                                        {item.active == undefined || item.active == null || item.active == false ?
                                            <SetCarouselActiveModal id={item._id.$oid}  buttonLabel={'Activate'}/>
                                            : <SetCarouselActiveModal id={item._id.$oid} buttonLabel={'Deactivate'}/>
                                        }
                                        <DeleteCarouselModel id={item._id.$oid}/>
                                    </div>
                                </CardFooter>
                            </Card>



                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default AllCarousels;