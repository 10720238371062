import React, {Fragment, useEffect} from 'react';

import {Navbar, InputGroup, InputGroupAddon, Button, Input, Badge} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../actions/general/generalActions";

const AdminMainHeaderNavContent = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    const onClick = () => {
        dispatch(logout())
    }

    return (
        <Navbar className={'main-header-nav'} expand="md">
            {/*<img style={{padding: '15px', maxHeight: '55px', height: '55px'}} src={require('../../../assets/images/nestid_logo.png')} alt=""/>*/}

            <Button style={{background: '#EE7D33'}} className={'ml-auto'} onClick={onClick}>
                Logoff
            </Button>

        </Navbar>
    );
};

export default AdminMainHeaderNavContent;