import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Col, Container, Row, Card, CardBody, CardHeader, CardFooter} from "reactstrap";
import LatestNewsBlogs from "../../../components/Blog/LatestNewsBlogs";
import ProfileCards from "../../../components/cards/ProfileCards";
import Testimonial from "../../../components/cards/Testimonial";
import ContactFormSideview from "../../../components/ContactFormSideview";
import {getAllTestimonials, getTeamMembers} from "../../../actions/general/generalActions";

const LeadershipView = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    useEffect(()=>{
        dispatch(getTeamMembers())
    }, [])
    return (
        <div className={'leadership-page'}>
            <Container fluid style={{width:' 90%'}}>
                <Row>
                    <Col md={8}>
                        <h1 className={'m-0 page-title'}>
                            Team
                        </h1>
                        <img className={'mb-5'} src={require('../../../assets/images/orange_half_circle.svg')} alt=""/>
                        <Row>
                            {general.teamMembers.map((teamMember, index) => (
                                <Col className={'mb-4'} md={4}>
                                    <ProfileCards image={teamMember.image} name={`${teamMember.first_name} ${teamMember.last_name}`} position={teamMember.position} link={teamMember.link}/>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col md={4} >
                        {/*<div style={{paddingLeft: '60px'}}>*/}
                        <div className={'sidemenucb-wrapper'}>
                            <LatestNewsBlogs/>
                            <ContactFormSideview/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LeadershipView;