import React, {useEffect} from 'react';
import {Container, Row, Col, Card, CardBody, CardFooter, Button} from 'reactstrap'
import Testimonial from "../../../components/cards/Testimonial";
import CreateNewTestimonialModal from "../../../components/CreateNewTestimonialModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllBlogs, getAllTestimonials, getBlog} from "../../../actions/general/generalActions";
import EditTestimonialModal from "../../../components/EditTestimonialModal";
import DeleteTestimonialModal from "../../../components/DeleteTestimonialModal";


const ViewAllTestimonials = () => {
    const dispatch = useDispatch()
    const general = useSelector(state => state.General)

    useEffect(() => {
        dispatch(getAllTestimonials())
    }, [general.updateToggle])

    return (
        <div>
            <Container className={'pt-5'}>
                <Row>
                    <Col md={2}>

                    </Col>
                    <Col md={8}>
                        <Row>
                            {
                                general.allTestimonials.map((item, index) => (
                                    <Col className={'mb-5'} md={12}>
                                        <Card>
                                            <CardBody>
                                                <Testimonial date={item.date} name={item.name} quote={item.quote}/>

                                            </CardBody>
                                            <CardFooter style={{background: 'transparent', border: 'none'}}>
                                                <div className={'d-flex'}>
                                                    <div className={'ml-auto mr-3'}>
                                                        <EditTestimonialModal content={item.quote}
                                                                              nameTestimonial={item.name} id={item._id.$oid}/>

                                                    </div>
                                                    <div>
                                                        <DeleteTestimonialModal uid={item._id.$oid} />

                                                    </div>
                                                </div>

                                            </CardFooter>
                                        </Card>
                                    </Col>
                                ))
                            }

                        </Row>
                    </Col>
                    <Col md={2}>
                        <CreateNewTestimonialModal/>

                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default ViewAllTestimonials;